<template>
  <b-container fluid id="detail">
    <b-row class="content justify-content-md-center">
      <b-col lg="5" md="8">
        <BackButton href="/admin/articles" text="Terug naar overzicht" />
        <h1 class="label mt-5">{{ article.title }}</h1>
        <div class="creation">
          <div class="creator">
            <Person />
            {{ article.creator.name }}
          </div>
          <div class="date">
            <Calendar />
            {{ article.created_at }}
          </div>

        </div>
        <div class="mt-5">
          <p> <strong>Locatie: </strong>{{ article.layer_of_goverment }}</p>
        </div>

        <div class="mt-5" v-if="article.layer_of_township">
          <p> <strong>Gemeente: </strong>{{ article.layer_of_township }}</p>
        </div>

        <div class="mt-5" v-if="article.accepted !== null">
          <p> <strong>Aangenomen: </strong> <span v-if="article.accepted === 1">Ja</span><span v-else>Nee</span></p>
        </div>

        <h3 class="label">Samenvatting</h3>
        <p>{{ article.description }}</p>

        <ul class="files">
          <li v-for="file in article.article_files" class="file">
            <a :href="file.source" class="link" target="_blank">
              <File /><span class="link-text">{{ file.source }}</span>
            </a>
          </li>
        </ul>

        <h3 class="label" v-if="article.sources !== null">Bronvermelding</h3>
        <ul class="sources">
          <li v-for="source in article.sources" class="source">
            <a :href="source" class="link" target="_blank">{{ source }}</a>
          </li>
        </ul>

        <h3 class="label">Labels toevoegen</h3>
        <TextFieldButton v-model="tags" :tags="tags" placeholder="Label" />
        <LabelList class="mb-4 p-0" :labels="tags" />

        <b-form @submit.prevent="setArticlePublic">
          <div v-if="this.$store.getters['getItemUpdatedSuccess']" class="success">Artikel goedgekeurd!</div>
          <div v-if="this.$store.getters['getItemDeletedSuccess']" class="success">Artikel verwijderd</div>

          <Button v-if="article.public === 0" type="submit" placeholder="Goedkeuren" />
          <Button v-b-modal.modal style="display: block; margin-top: 20px; background-color: red !important;"
            class="delete" placeholder="Verwijder artikel" />

          <b-modal centered id="modal" ref="modal" hide-footer size="sm" title="Waarschuwing!">
            <p>Weet je zeker dat je dit artikel wil verwijderen?</p>
            <Button class="button-no" placeholder="Nee" @click.native="$refs['modal'].hide()"
              style="background-color: red;" />
            <Button class="button-yes" placeholder="Ja" @click.native="deleteArticle"
              style="background-color: var(--rik-orange)" />
          </b-modal>
        </b-form>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import BackButton from "../components/BackButton";
import TextArea from "../components/TextArea";
import Button from "../components/Button";
import Select from "../components/Select";
import File from "../components/icons/File";
import Calendar from "../components/icons/Calendar";
import Person from "../components/icons/Person";
import TextField from "../components/TextField";
import SourceList from "../components/SourceList";
import TextFieldButton from "../components/TextFieldButton";
import LabelList from "../components/LabelList";
export default {
  name: "AdminQuestion",
  components: {
    LabelList,
    TextFieldButton,
    SourceList, TextField, Select, Button, TextArea, File, Calendar, Person, BackButton
  },
  data() {
    return {
      article: {},
      tags: [],
      error: false
    }
  },
  methods: {
    setArticlePublic() {
      this.article.public = 1;
      this.article.Tags = this.tags.map(tag => tag.toLowerCase());
      this.$store.dispatch("updateArticle", this.article)
    },
    deleteArticle() {
      this.$refs['modal'].hide();
      this.$store.dispatch("deleteArticle", this.$route.params.id);
    }
  },
  async created() {
    if (localStorage.getItem('access_token')) {
      await this.$store.dispatch("getCurrentUser");
      if ((this.$store.getters['getCurrentUser'].roles[0].name !== "super-admin")) {
        await this.$router.push("/");
      } else {
        await this.$store.dispatch("getArticle", this.$route.params.id);
        this.article = this.$store.getters['getArticle'];

        if (this.article.creator === null) {
          this.article.creator = { name: "verwijderd" };
        }
        if (this.article.sources !== null) {
          this.article.sources = this.article.sources.split(",");
        }
        this.article.Tags.forEach(tag => {
          this.tags.push(tag.name.nl);
        })
      }
    }
  }
}
</script>

<style scoped>
#detail {
  background-color: var(--default-background) !important;
}
</style>

<template>
  <b-container fluid id="question">
    <div class="content">
      <div class="wrapper">
        <BackButton text="Terug naar overzicht" href="/" />
        <h1>Stel een vraag</h1>
        <div class="tile">
          <b-form @submit.prevent="createQuestion">
            <div v-if="this.$store.getters['getQuestionError']" class="error ml-2">Niet alle velden zijn correct ingevuld</div>
            <div v-if="this.$store.getters['getQuestionCreatedSuccess']" class="success">Vraag aangemaakt</div>
            <Select class="mt-4" selectedValue="Kies een thema" placeholder="Kies een thema" :options="this.$store.getters['getCategoryList']" v-model="type" />
            <TextArea class="question" placeholder="Vul hier de vraag in" v-model="question" type="text" />
            <Button type="submit" placeholder="Stel vraag" />
          </b-form>
        </div>
      </div>
    </div>
  </b-container>
</template>

<script>
import BackButton from "../components/BackButton";
import TextArea from "../components/TextArea";
import Button from "../components/Button";
import Select from "../components/Select";
export default {
  name: "AskQuestion",
  components: {Select, Button, TextArea, BackButton},
  data() {
    return {
      question: "",
      type: ""
    }
  },
  methods: {
    createQuestion() {
      let question = {
        question: this.question,
        category_id: this.type
      }
      this.$store.dispatch("createQuestion", question);
    }
  },
  async created() {
    await this.$store.dispatch("getListCategories");
  }
}
</script>

<template>
    <b-container>
        <div class="content">
            <div class="wrapper">
                <h1 class="mt-5 mb-4">Jouw account</h1>
                <div class="tile">
                    <div v-if="$store.getters['getForgotPasswordSuccess']" class="success">Wachtwoord reset verstuurd</div>
                    <label for="">Naam</label>
                    <TextField class="mb-4" type="text" placeholder="Naam" :value="$store.getters['getCurrentUser'].name"
                        disabled />
                    <label for="">Email</label>
                    <TextField class="mb-4" type="text" placeholder="Email" :value="$store.getters['getCurrentUser'].email"
                        disabled />
                    <label for="">Functie</label>
                    <TextField class="mb-4" type="text" placeholder="Naam"
                        :value="$store.getters['getCurrentUser'].political_role" disabled />
                    <Button @click.native="newPassword" :placeholder="isLoading ? 'laden..' : 'Wachtwoord reset'" />
                </div>
            </div>
        </div>
    </b-container>
</template>


<script>
import TextField from "./../components/TextField.vue"
import Button from "./../components/Button.vue"

export default {
    data:{
        return: {
            isLoading: false
        }
    },
    components: {
        TextField,
        Button
    },
    async created() {
        document.body.classList.add("my-account");
        await this.$store.dispatch('getCurrentUser');
    },
    methods: {
        async newPassword() {
            this.isLoading = true;
            await this.$store.dispatch("userForgot", this.$store.getters['getCurrentUser'].email);
            this.isLoading = false;
        }
    }
}
</script>

<style>
h1 {
    font-weight: bold;
    margin: 30px 0 50px 0;
}

.tile {
    background: var(--rik-white);
    padding: 20px;
    border-radius: 18px;
}

.my-account {
    min-height: 100vh;
    background-color: var(--default-background-admin) !important;
}

.content {
    width: 60%;
    margin: 0 auto;
}</style>
<template>
  <div class="pt-5 back-button"><a @click="$router.back()"  style="color: var(--default-text) !important;"><ArrowLeft class="mr-4"/>{{ text }}</a></div>
</template>
<script>
import ArrowLeft from "./icons/ArrowLeft";
export default {
  props: ['text'],
  components: {ArrowLeft}
}
</script>

<style>
  .back-button:hover {
    cursor: pointer;
  }
</style>

<template>
  <div id="expert">
    <b-container fluid>
      <h2>Welkom expert </h2>
      <b-row>
        <b-col lg="3" md="4" sm="5">
          <div v-if="!showsAllItems" @click="showAllItems" class="tile">
            <h4>Alle artikelen</h4>
            <div class="wrapper">
              <h4><b>{{this.$store.getters['getArticles'].length}}</b></h4>
              <p class="p-0 m-0">Bekijk <ArrowRight/></p>
            </div>
          </div>
          <div v-if="showsAllItems" @click="showAllOpenItems" class="tile">
            <h4>Openstaande artikelen</h4>
            <div class="wrapper">
              <h4><b>{{this.$store.getters['getArticles'].filter(a => a.public === 0).length}}</b></h4>
              <p class="p-0 m-0">Bekijk <ArrowRight/></p>
            </div>
          </div>
        </b-col>
        <b-col lg="6" offset-lg="3" md="4" offset-md="4" sm="5" offset-sm="2">
          <Button class="button-create px-5" @click.native="$router.push({name: 'Add item'})" placeholder="Artikel toevoegen"/>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="7" md="12">
          <div class="tile">
            <p v-if="!showsAllItems"><strong>Openstaande artikelen</strong></p>
            <p v-else><strong>Alle artikelen</strong></p>
            <TextField  @keyup.native="searchedArticles" @change.native="searchedArticles" v-model="searchedArticle" class="expert-input" icon="search" placeholder="Zoeken"/>
            <div class="table-responsive">
              <table class="table ">
                <thead>
                <tr>
                  <th class="no-top-border" scope="col">Naam</th>
                  <th class="no-top-border" scope="col">Auteur</th>
                  <th class="no-top-border" scope="col">Goedgekeurd</th>
                  <th class="no-top-border" scope="col">Aangemaakt</th>
                  <th class="no-top-border" scope="col">Actie</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="item in this.items" :key="item.id">
                  <td>{{item.title}}</td>
                  <td>{{item.creator.name}}</td>
                  <td v-if="item.public === 0">Niet goedgekeurd</td>
                  <td v-else>Goed gekeurd</td>
                  <td>{{item.created_at}}</td>
                  <td><Button @click.native="$router.push({name: 'Edit item', params: { id: item.id}})" class="px-5" placeholder="Bewerken"/></td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </b-col>
        <b-col lg="5" md="12">
          <div class="tile">
            <p><strong>Openstaande vragen</strong></p>
            <TextField @keyup.native="searchQuestions" @change.native="searchQuestions" v-model="searchedQuestion" class="expert-input" icon="search" placeholder="Zoeken"/>
            <div class="table-responsive">
              <table class="table ">
                <thead>
                <tr>
                  <th class="no-top-border" scope="col">Vraag</th>
                  <th class="no-top-border" scope="col">Aangemaakt</th>
                  <th class="no-top-border" scope="col">Actie</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="question in this.questions" :key="question.id">
                  <td>{{question.question}}</td>
                  <td>{{question.created_at}}</td>
                  <td><Button @click.native="$router.push({name: 'AnsQuestion', params: { id: question.id}})" class="px-5" placeholder="Beantwoorden"/></td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Button from "../components/Button";
import TextField from "../components/TextField";
import ArrowRight from "../components/icons/ArrowRight";
export default {
  name: "ExpertDashboard",
  components: {ArrowRight, TextField, Button},
  data() {
    return {
      searchedItem: "",
      items: [],
      showsAllItems: false,
      questions: [],
      searchedQuestion: "",
      searchedArticle: ""
    }
  },
  methods: {
    async searchQuestions(){
      let questions = this.$store.getters['getOpenQuestions'];
      if(this.searchedQuestion !== "") {
        this.questions = questions.filter((question) => {
          return question.question.toLocaleLowerCase().includes(this.searchedQuestion.toLocaleLowerCase());
        });
        return;
      }
      this.questions = questions;
    },
    async searchedArticles(){
      if (this.showsAllItems) {
        this.showAllItems();
      } else {
        this.showAllOpenItems()
      }
      if (this.searchedArticle !== "") {
        this.items = this.items.filter((item) => {
          return item.title.toLocaleLowerCase().includes(this.searchedArticle.toLocaleLowerCase());
        });
      }
    },
    showAllItems(){
      this.showsAllItems = true;
      this.items = this.$store.getters['getArticles'];
    },
    showAllOpenItems() {
      this.showsAllItems = false
      this.items = this.$store.getters['getArticles'].filter((article) => {
        return article.public === 0
      });
    },

  },
  async created() {
    if(localStorage.getItem('access_token')){
      await this.$store.dispatch("getCurrentUser");
      if((this.$store.getters['getCurrentUser'].roles[0].name !== "expert")){
        await this.$router.push("/");
      }else{
        await this.$store.dispatch("getAllArticles");
        this.items = this.$store.getters['getArticles'].filter((article) => {
          return article.public === 0
        });

        await this.$store.dispatch("getAllOpenQuestions");
        this.questions = this.$store.getters['getOpenQuestions'];
      }
    }
  }
}
</script>

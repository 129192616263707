<template>
  <b-container fluid id="adduser">
    <b-row class="justify-content-md-center">
      <b-col lg="6">
        <BackButton href="/admin" text="Terug naar overzicht"/>
        <h1 class="mt-5">Gebruiker aanmaken</h1>

        <form @submit.prevent="createUser">
          <p class="error ml-2" v-if="this.$store.getters['getUserError']">Niet alle velden zijn correct ingevuld</p>

          <div v-if="this.$store.getters['getUserCreatedSuccess']" class="success">Gebruiker aangemaakt</div>
          <TextField class="mb-4" type="text" placeholder="Naam" v-model="name"/>
          <TextField class="mb-4" type="text" placeholder="Email" v-model="email"/>
          <Select selectedValue="Kies een rol" class="mb-4" placeholder="Kies een rol" v-model="role" :options="roles" />
          <Select selectedValue="Kies een titel" class="mb-4" placeholder="Kies een titel" v-model="politcal_role" :options="titles" />
          <Button v-if="!isLoading" type="submit" class="mt-3" placeholder="Gebruiker aanmaken" />
          <b-spinner v-else style="width: 3rem; height: 3rem;" label="Large Spinner"></b-spinner>
        </form>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import TextField from "../components/TextField";
import Select from "../components/Select";
import Button from "../components/Button";
import BackButton from "../components/BackButton";
import { required, email } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      name: "",
      email: "",
      role: "",
      theme: "",
      sub_theme: "",
      roles: this.$store.getters.getPermRoles,
      politcal_role: "",
      titles: [
        { value: 'Statenlid', text: 'Statenlid' },
        { value: 'Senator', text: 'Senator' },
        { value: 'Tweede Kamer Lid', text: 'Tweede Kamer Lid' },
        {value: 'Medewerker RI', text: 'Medewerker RI'},
        {value: 'Gemeenteraadslid', text: 'Gemeenteraadslid'}
      ],
      isLoading: false
    }
  },
  components: {BackButton, Button, Select, TextField},
  methods: {
    async createUser() {
      this.isLoading = true;
      await this.$store.dispatch("userRegister", {name: this.name, email: this.email, password: this.password, role: this.role, political_role: this.politcal_role});
      this.isLoading = false;
    }
  },
  async created(){
    if(localStorage.getItem('access_token')){
      await this.$store.dispatch("getCurrentUser");
      if((this.$store.getters['getCurrentUser'].roles[0].name !== "super-admin")){
        await this.$router.push("/");
      }
    }
  }
}
</script>

<style>
a, a:hover {
  text-decoration: none !important;
  color: #000000 !important;
}
</style>

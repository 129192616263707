<template>
  <svg width="27" height="24" viewBox="0 0 27 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20.6423 17.4008C18.9378 16.5851 16.0967 15.5 13.0438 15.5C9.99102 15.5 7.14991 16.5851 5.44538 17.4008C4.37876 17.9113 3.71713 18.963 3.56588 20.1358L3.26099 22.5H22.8267L22.5218 20.1358C22.3705 18.963 21.7089 17.9113 20.6423 17.4008Z" stroke="var(--default-text)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M13.0438 11C15.7452 11 17.9352 8.98528 17.9352 6.5C17.9352 4.01472 15.7452 2 13.0438 2C10.3423 2 8.15234 4.01472 8.15234 6.5C8.15234 8.98528 10.3423 11 13.0438 11Z" stroke="var(--default-text)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "Person"
}
</script>
<template>
  <div v-if="this.loading">
    <b-spinner style="width: 3rem; height: 3rem;" label="Large Spinner"></b-spinner>
  </div>
  <b-button v-else class="Button shadow-none">{{ placeholder }}</b-button>
</template>

<script>
export default {
  name: "Button",
  props: {
    placeholder: {
      type: String,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<template>
  <div id="notifications">

    <Notification slot="button-content" @click.native="show = !show"/>

    <div class="dropdown" :class="show ? 'content-show' : 'content-hide'">
      <b-dropdown-item disabled v-if="notifications"><b>Nieuwe meldingen</b></b-dropdown-item>
      <b-dropdown-item disabled v-if="!notifications"><b>Geen nieuwe meldingen</b></b-dropdown-item>

      <div v-if="notifications">
        <b-dropdown-divider ></b-dropdown-divider>
        <b-dropdown-item v-for="notification in notifications" :key="notification.id">{{ notification.text }}</b-dropdown-item></div>
      </div>

  </div>
</template>

<script>
import Notification from "./icons/Notification";
export default {
  name: "Notifications",
  components: {Notification},
  data() {
    return {
      show: false,
    }
  },
  props: ['notifications']
}
</script>

<style scoped>

</style>
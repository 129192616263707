<template>
  <label class="container-checkbox">{{ placeholder }}
    <input type="checkbox" :checked="isChecked" @change="change">
    <span class="checkmark"></span>
  </label>
</template>

<script>
  export default {
    name: "Checkbox",
    props: [
        "placeholder",
        "value",
        "isChecked"
    ],
    methods: {
      change(e) {
        this.$emit('input', this.content = e.target.checked)
      }
    }
  }
</script>
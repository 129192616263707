<template>
  <div class="input tf-btn-wrapper">
    <b-form-input v-model="source" type="text" class="txt-field shadow-none" @input="handleInput" :placeholder="placeholder"></b-form-input>
    <b-button @click="addSource" class="Button tf-btn shadow-none">Add</b-button>
  </div>
</template>

<script>
export default {
  name: "TextFieldButton",
  data(){
    return {
      source: "",
      sources: []
    }
  },
  props: ["placeholder", "tags"],
  methods: {
    handleInput (e) {
      this.$emit('input', this.content = this.sources)
    },
    addSource() {
      if (this.source !== "") {
        this.sources.push(this.source);
        this.source = "";
      }
    }
  },
  created() {
    this.sources = this.tags;
  }
}
</script>

<template>
  <b-container fluid id="login" class="p-0">
    <b-row  class="m-0 p-0">
      <b-col class="image-logo m-0 p-0">
        <img class="bg" src="../assets/images/school_of_athens.jpg" alt="">
        <div class="content">
          <img class="logo" src="../assets/images/fvd-logo.png" alt="">
          <h1 class="header-text">Renaissance Instituut Kennisbank</h1>
        </div>
      </b-col>
      <b-col class="m-0 p-0" align-self="center">
        <b-form class="login-form" @submit.prevent="newPassword">
          <h1>Nieuw wachtwoord</h1>
          <div v-if="this.$store.getters['getForgotPasswordSuccess']" class="success">Mail verzonden</div>
          <div v-if="this.$store.getters['getUserError']" class="error">Vul een geldig email adres in</div>
          <TextField class="mt-3" type="text" placeholder="Email" v-model="email"/>
          <Button class="mt-3" type="submit" placeholder="Verzend email"/>
        </b-form>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import TextField from "../components/TextField";
import Button from "../components/Button";
import { required, email } from "vuelidate/lib/validators";

export default {
  name: "Forgot-password",
  components: {TextField, Button},
  data() {
    return {
      email: ""
    }
  },
  methods: {
    newPassword() {
      this.$store.dispatch("userForgot", this.email);
    }
  }
}
</script>
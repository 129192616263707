<template>
  <b-container fluid id="question">
    <div class="content">
      <div class="wrapper">
        <BackButton text="Terug naar overzicht" />
        <h1 v-if="user.roles[0].name !== 'user'">Beantwoord vraag</h1>
        <h1 v-if="user.roles[0].name === 'user'">Overzicht vraag</h1>
        <div class="tile">
          <div v-if="this.$store.getters['getReactionCreatedSuccess']" class="success">Vraag beantwoord</div>
          <div v-if="this.$store.getters['getQuestionDeletedSuccess']" class="success">Vraag verwijderd</div>
          <b-form @submit.prevent="answerQuestion">
            <h4>{{ question.question }}</h4>
            <div v-if="question.reactions.length > 0">
              <p>Antwoord(en):</p>
              <p v-for="reaction in question.reactions" :key="reaction.id">{{reaction.user.name}}: {{reaction.reaction}}</p>
            </div>
            <div v-if="user.roles[0].name !== 'user'">
              <Select disabled="1" class="mt-4" :selectedValue="question.category_id" placeholder="Kies een thema" :options="this.$store.getters['getCategoryList']" v-model="question.category_id" />
              <TextArea class="question" v-model="reaction" placeholder="Vul hier het antwoord in" type="text" />
              <div v-if="this.$store.getters['getReactionError']" class="error ml-2">Niet alle velden zijn correct ingevuld</div>
              <Button type="submit" placeholder="Verzend antwoord" />
              <Button v-b-modal.modal v-if="user.roles[0].name === 'super-admin'" class="delete" placeholder="Verwijder vraag" />

              <b-modal centered id="modal" ref="modal" hide-footer size="sm" title="Waarschuwing!">
                <p>Weet je zeker dat je deze vraag wil verwijderen?</p>
                <Button class="button-no" placeholder="Nee" @click.native="$refs['modal'].hide()" style="background-color: red;" />
                <Button class="button-yes" placeholder="Ja" @click.native="deleteQuestion" style="background-color: var(--rik-orange)" />
              </b-modal>
            </div>

          </b-form>
        </div>
      </div>
    </div>
  </b-container>
</template>

<script>
import BackButton from "../components/BackButton";
import TextArea from "../components/TextArea";
import Button from "../components/Button";
import Select from "../components/Select";
export default {
  name: "AnswerQuestion",
  components: {Select, Button, TextArea, BackButton},
  data() {
    return {
      question: {},
      reaction: "",
      user: {}
    }
  },
  methods: {
    hideModal() {
      this.$refs['modal'].hide()
    },
    answerQuestion() {
      let reaction = {
        reaction: this.reaction,
        question_id: this.question.id
      }
      this.$store.dispatch("createReaction", reaction);
    },
    deleteQuestion() {
      this.$refs['modal'].hide();
      this.$store.dispatch("deleteQuestion", this.question.id)
    }
  },
  async beforeCreate() {
    if(localStorage.getItem('access_token')){
      await this.$store.dispatch("getCurrentUser");
      await this.$store.dispatch("getListCategories");
      this.user = this.$store.getters['getCurrentUser'];
      await this.$store.dispatch("getQuestion", this.$route.params.id);
      this.question = this.$store.getters['getQuestion'];
      this.question.reactions.forEach(reaction => {
        if (reaction.user === null) {
          reaction.user = {name: "verwijderd"}
        }
      });
    }
  }
}
</script>

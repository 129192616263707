<template>
  <p @click="handleChange" class="font-weight-bold sort">
    Sorteer op: {{ sort.find(s => s.value === active_sort).text }}
    <Chevrondown v-if="active_sort==='desc'"/>
    <Chevronup v-if="active_sort==='asc'"/>
  </p>
</template>

<script>
import Chevronup from "./icons/Chevronup";
import Chevrondown from "./icons/Chevrondown";
export default {
  name: "SortButton",
  components: {Chevrondown, Chevronup},
  data() {
    return {
      active_sort: "",
      sort: [
        {text: "Laatst toegevoegd", value: "desc"},
        {text: "Eerst toegevoegd", value: "asc"},
      ]
    }
  },
  props: ["default"],
  methods: {
    handleChange(e) {
      this.active_sort = this.active_sort === 'desc' ? 'asc' : 'desc';
      this.$emit('change', this.active_sort);
    }
  },
  created() {
    this.active_sort = this.default;
  }
}
</script>

<style scoped>

</style>
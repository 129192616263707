<template>
  <b-form-textarea rows="6" class="txt-area shadow-none" :value="value" @input="handleInput" :placeholder="placeholder"></b-form-textarea>
</template>

<script>
export default {
  name: "TextArea",
  props: {
    placeholder: {
      type: String,
      required: true
    },
    value: {
      type: String
    }
  },
  methods: {
    handleInput (e) {
      this.$emit('input', this.content = e)
    }
  }
}
</script>

<style scoped>
</style>
<template>
  <div class="input">
    <b-form-input :type="type" :value="value" class="txt-field shadow-none" @input="handleInput" :placeholder="placeholder" :disabled="disabled"></b-form-input>
    <SearchIcon class="icon" v-if="icon === 'search'"/>
  </div>
</template>

<script>
import SearchIcon from "./icons/SearchIcon";
export default {
  name: "TextField",
  components: {SearchIcon},
  data(){
    return{
      content: ""
    }
  },
  props: {
    placeholder: {
      type: String,
      required: true
    },
    value: {
      type: String
    },
    type: {
      type: String
    },
    icon: String,
    disabled:{
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleInput (e) {
      this.$emit('input', this.content = e)
    }
  },
  created() {
    this.content = this.value
  }
}
</script>

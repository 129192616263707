<template>
  <div class="label-list">
    <div class="label-list-item mt-3 ml-3" v-for="label in labels">{{ label }}
      <Cross @click.native="remove(label)"/>
    </div>
  </div>
</template>

<script>
import Chevrondown from "./icons/Chevrondown";
import Cross from "./icons/Cross";
export default {
  name: "LabelList",
  data() {
    return {
    }
  },
  components: {Cross, Chevrondown},
  props: ["labels"],
  methods: {
    remove(label) {
      let id = this.labels.indexOf(label);
      this.labels.splice(id, 1);
    }
  }
}
</script>
<template>
  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.05371 14.928L13.0888 8.03748L19.9793 15.0726" stroke="var(--default-text)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>

</template>

<script>
export default {
  name: "Chevronup"
}
</script>

<style scoped>

</style>
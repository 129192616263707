<template>
  <div id="admin">
    <b-container fluid>
      <h2>Welkom admin</h2>
      <b-spinner v-if="isLoading" style="width: 3rem; height: 3rem;" label="Large Spinner"></b-spinner>
      <b-row v-else>
        <b-col lg="2" sm="4">
          <div class="tile" @click="$router.push('/admin/articles')">
            <h4>Artikelen</h4>
            <div class="wrapper">
              <h4><b>{{articles.length}}</b></h4>
              <p class="p-0 m-0">Bekijk <ArrowRight/></p>
            </div>
          </div>
        </b-col>
        <b-col lg="2" sm="4">
          <div class="tile" @click="$router.push('/admin/categories')">
            <h4>Thema's</h4>
            <div class="wrapper">
              <h4><b>{{categories.filter(article => article.parent_id === null).length}}</b></h4>
              <p class="p-0 m-0">Bekijk <ArrowRight/></p>
            </div>
          </div>
        </b-col>
        <b-col lg="2" sm="4">
          <div class="tile" @click="$router.push('/admin/questions')">
            <h4>Vragen</h4>
            <div class="wrapper">
              <h4><b>{{questions.length}}</b></h4>
              <p class="p-0 m-0">Bekijk <ArrowRight/></p>
            </div>
          </div>
        </b-col>
        <b-col offset-lg="4">
          <Button class="button-create" @click.native="$router.push({name: 'Add user'})" placeholder="Gebruiker aanmaken"/>
        </b-col>
      </b-row>
      <b-row  v-if="!isLoading">
        <b-col lg="12">
          <div class="tile">
            <p><strong>Gebruikers</strong></p>
            <TextField @keyup.native="searchInUsers" @change.native="searchInUsers" v-model="searchedUser" class="admin-input" icon="search" placeholder="Zoeken"/>
            <div class="table-responsive">
              <table class="table " style="color: var(--default-text) !important;">
                <thead>
                <tr>
                  <th class="no-top-border" scope="col">Id</th>
                  <th class="no-top-border" scope="col">Naam</th>
                  <th class="no-top-border" scope="col">Rol</th>
                  <th class="no-top-border" scope="col">Titel</th>
                  <th class="no-top-border" scope="col">Aangemaakt op</th>
                  <th class="no-top-border" scope="col">Actie</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="user in this.users">
                  <th scope="row">{{user.id}}</th>
                  <td>{{user.name}}</td>
                  <td>{{user.role[0]}}</td>
                  <td>{{user.political_role}}</td>
                  <td>{{user.created_at}}</td>
                  <td><Button @click.native="$router.push({name: 'Edit user', params: { id: user.id}})" placeholder="Bewerken"/></td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
  import Button from "../components/Button";
  import TextField from "../components/TextField";
  import ArrowRight from "../components/icons/ArrowRight";
  export default {
    name: "AdminDashboard",
    components: {ArrowRight, TextField, Button},
    data() {
      return {
        searchedUser: "",
        users: [],
        articles: [],
        categories: [],
        questions: [],
        isLoading: false
      }
    },
    methods: {
      async searchInUsers(){
        let users = this.$store.getters['getUsers'];
        if(this.searchedUser === ""){
          await this.$store.dispatch("getAllUsers");
          this.users = this.$store.getters['getUsers'];
        }else{
          this.users = users.filter((user) => {
            return user.name.toLocaleLowerCase().includes(this.searchedUser.toLocaleLowerCase());
          });
        }
      }
    },
    async created(){
      if(localStorage.getItem('access_token')){
        await this.$store.dispatch("getCurrentUser");
        if((this.$store.getters['getCurrentUser'].roles[0].name !== "super-admin")){
          await this.$router.push("/");
        }else{

          this.isLoading = true;

          await this.$store.dispatch("getAllArticles");
          this.articles =  this.$store.getters['getArticles'];

          await this.$store.dispatch("getAllCategories", {search: "", tags: "", sort: "desc"});
          this.categories = this.$store.getters['getCategories'].categories;

          await this.$store.dispatch("getAllUsers");
          this.users = this.$store.getters['getUsers'];

          await this.$store.dispatch("getAllQuestions");
          this.questions =  this.$store.getters['getQuestions'];

          this.isLoading = false;

        }
      }
    },
  }
</script>

<style scoped>
 .spinner-border{
    display: block;
    margin: 0 auto;
  }</style>

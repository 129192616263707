<template>
  <div id="app">
   <Toolbar v-if="this.$store.getters['getAccessToken']"/>
    <router-view/>
  </div>
</template>

<script>
import Toolbar from "./components/Toolbar";
import router from "./router";
export default {
  components: {Toolbar},
  async created(){
    if(localStorage.getItem('access_token')){
      await this.$store.dispatch("getCurrentUser");

      if (!this.$store.getters['getCurrentUser']) {
        router.push("/login");
      }
    }
  }
}
</script>

<style>

</style>

<template>
  <div id="admin">
    <b-container fluid>
      <b-row class="justify-content-md-center">
        <b-col lg="10">
          <BackButton text="Terug naar admin" href="/admin" />
          <h2 class="p-0 my-5">Alle vragen</h2>
        </b-col>
        <b-col lg="10">
          <div class="tile">
            <p><strong>Vragen</strong></p>
            <TextField @keyup.native="searchInQuestions" @change.native="searchInQuestions" v-model="searchedQuestion" class="admin-input" icon="search" placeholder="Zoeken"/>
            <div class="table-responsive">
              <table class="table " style="color: var(--default-text) !important;">
                <thead>
                <tr>
                  <th class="no-top-border" scope="col">Id</th>
                  <th class="no-top-border" scope="col">Vraag</th>
                  <th class="no-top-border" scope="col">Auteur</th>
                  <th class="no-top-border" scope="col">Aangemaakt op</th>
                  <th class="no-top-border" scope="col">Actie</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="question in this.questions">
                  <th scope="row">{{question.id}}</th>
                  <td>{{question.question}}</td>
                  <td>{{question.user.name}}</td>
<!--                  <td>test</td>-->
                  <td>{{question.created_at}}</td>
                  <td><Button @click.native="$router.push({name: 'AnsQuestion', params: { id: question.id}})" placeholder="Bekijken"/></td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Button from "../../components/Button";
import TextField from "../../components/TextField";
import ArrowRight from "../../components/icons/ArrowRight";
import BackButton from "../../components/BackButton";
export default {
  name: "AdminQuestions",
  components: {BackButton, ArrowRight, TextField, Button},
  data() {
    return {
      searchedQuestion: "",
      questions: []
    }
  },
  methods: {
    async searchInQuestions(){
      let questionsStart = this.$store.getters['getQuestions'];
      if(this.searchedQuestion === ""){
        await this.$store.dispatch("getAllQuestions");
        this.questions = this.$store.getters['getQuestions'];
      }else{
        this.questions = questionsStart.filter((question) => {
          return question.question.toLocaleLowerCase().includes(this.searchedQuestion.toLocaleLowerCase());
        });
      }
    },
    async getQuestions() {
      this.table = "questions"
      await this.$store.dispatch("getAllQuestions");
      this.questions =  this.$store.getters['getQuestions'];
    },
  },
  async created(){
    if(localStorage.getItem('access_token')){
      await this.$store.dispatch("getCurrentUser");
      if((this.$store.getters['getCurrentUser'].roles[0].name !== "super-admin")){
        await this.$router.push("/");
      }else{
        await this.$store.dispatch("getAllQuestions");
        this.questions =  this.$store.getters['getQuestions'];
        this.questions.forEach(question => {
          if (question.user === null) {
            question.user = {name: "verwijderd"};
          }
        });
      }
    }
  },
}
</script>

import axios from 'axios'
import router from '../router'

// let url = 'https://ridb-api.retrii.dev'
let url = 'https://api.ridab.nl'
// let url = 'http://127.0.0.1:8000'


const state = {
    access_token: localStorage.getItem('access_token') || '',
    user: '',
    user_to_edit: [],
    user_roles: [],
    perm_roles: [
        { value: 'user', text: 'Gebruiker' },
        { value: 'expert', text: 'Expert' },
        { value: 'gemeenteraadslid-plus', text: 'Gemeenteraadslid-plus' },
        { value: 'statenleden-plus', text: 'Statenlid-plus' },
        { value: 'super-admin', text: 'Administrator' }
    ],
    userCreatedSuccess: false,
    editUserSuccess: false,
    currentUser: "",
    editedPermissions: false,
    forgotPasswordSuccess: false,
    notifications: [],
    user_error: "",
    userArticles: []
};


const getters = {
    getCurrentUser: (state) => {
        return state.currentUser;
    },
    getAccessToken: (state) => {
        return state.access_token;
    },
    getUser: (state) => {
        return state.user;
    },
    getUserToEdit: (state) => {
        return state.user_to_edit;
    },
    getPermRoles: (state) => {
        return state.perm_roles;
    },
    getUserRoles: (state) => {
        return state.user_roles;
    },
    getUserCreatedSuccess: (state) => {
        return state.userCreatedSuccess;
    },
    getUserEditedSuccess: (state) => {
        return state.editUserSuccess;
    },
    getUserEditedPermissionsSuccess: (state) => {
        return state.editedPermissions;
    },
    getNotifications: (state) => {
        return state.notifications;
    },
    getForgotPasswordSuccess: (state) => {
        return state.forgotPasswordSuccess;
    },
    getUserError: (state) => {
        return state.user_error;
    },
    getUserArticles: (state) => {
        return state.userArticles;
    }
};


const actions = {
    async getMyArticles({ commit }) {
        await axios.get(`${url}/api/userarticles`, {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + localStorage.getItem('access_token')
            }
        })
            .then((response) => {
                commit("SET_USER_ARTICLES", response.data);
            })
            .catch(err => {
                console.log(err)
            })
    },
    async getCurrentUser({ commit }) {
        await axios.get(`${url}/api/user/current`, {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + localStorage.getItem('access_token')
            }
        })
            .then((response) => {
                commit("SET_CURRENT_USER", response.data);
            })
            .catch(err => {
                console.log(err)
            })
    },
    async userLogin({ commit }, user) {
        await axios.post(`${url}` + '/api/login', {
            email: user.email,
            password: user.password
        })
            .then((response) => {
                localStorage.setItem('access_token', response.data.token);
                commit('SET_USER', { user: response.data.user, token: response.data.token });
                commit('SET_USER_ERROR', "");
                router.push('/');
            })
            .catch((error) => {
                commit("SET_USER_ERROR", error.response.data)
            })
    },

    async userForgot({ commit }, email) {
        await axios.post(`${url}` + '/api/password/forgot-password', {
            email: email
        })
            .then((response) => {
                commit("SET_PASSWORD_FORGOT_SUCCESS", true);
                commit("SET_USER_ERROR", "");
                setTimeout(() => {
                    commit("SET_PASSWORD_FORGOT_SUCCESS", false);
                    //router.push("/login");
                }, 1500)
            })
            .catch((error) => {
                commit("SET_USER_ERROR", error.response.data);
            })
    },
    userLogout({ commit }) {
        localStorage.clear();
        commit('SET_USER', '');
        router.push('/login');
    },
    async userReset({ commit }, { token, email, password }) {
        await axios.post(`${url}` + '/api/password/reset', {
            token: token,
            email: email,
            password: password,
            password_confirmation: password
        })
            .then((response) => {
                commit("SET_USER_ERROR", "");
                router.push("/login");
            })
            .catch((error) => {
                commit("SET_USER_ERROR", error.response.data);
            })
    },
    async userRegister({ commit }, user) {
        await axios({
            method: 'post',
            url: `${url}` + '/api/user/create',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + localStorage.getItem('access_token')
            },
            data: {
                name: user.name,
                email: user.email,
                role: user.role,
                political_role: user.political_role
            }
        })
            .then((response) => {
                commit("SET_USER_CREATED", true);
                commit("SET_USER_ERROR", "")
                setTimeout(() => {
                    commit("SET_USER_CREATED", false);
                    router.push("/admin")
                }, 1500)
            })
            .catch((error) => {
                commit("SET_USER_ERROR", error.response.data)
                console.log(error.response.data)
            })
    },
    async userGet({ commit }, id) {
        await axios.get(`${url}/api/user/${id}`, {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + localStorage.getItem('access_token')
            }
        })
            .then((response) => {
                //console.log(response.data)
                commit('SET_USER_TO_EDIT_ADMIN', response.data)
            })
            .catch((err) => {
                console.log(err);
            })
    },
    async userRolesGet({ commit }, id) {
        await axios.get(`${url}/api/user/roles/${id}`, {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + localStorage.getItem('access_token')
            }
        })
            .then((response) => {
                commit('SET_USER_ROLES', response.data);
            })
            .catch((err) => {
                console.log(err);
            })
    },
    async userRolesUpdate({ commit }, { id, roles }) {
        await axios({
            method: 'post',
            url: `${url}` + '/api/user/roles',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + localStorage.getItem('access_token')
            },
            data: {
                user_id: id,
                roles: roles
            }
        })
            .then((response) => {
                commit('SET_USER_EDITED_RIGHTS_SUCCESS', true);
                setTimeout(() => {
                    commit('SET_USER_EDITED_RIGHTS_SUCCESS', false);
                }, 1500)
            })
            .catch((err) => {
                console.log(err);
            })
    },
    async userUpdate({ commit }, user) {
        if (user.password) {
            await axios.patch(`${url}/api/user/${user.id}`, {

                name: user.name,
                email: user.email,
                role: user.role,
                password: user.password,
                political_role: user.political_role,
                wants_email: user.wants_email
            }, {
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem('access_token')
                },
            })
                .then((response) => {
                    commit("SET_USER_EDITED", true);
                    commit("SET_USER_ERROR", "");
                    setTimeout(() => {
                        commit("SET_USER_EDITED", false);
                    }, 1500)
                })
                .catch(error => {
                    commit("SET_USER_ERROR", error.response.data);
                })
        } else {
            await axios.patch(`${url}/api/user/${user.id}`, {

                name: user.name,
                email: user.email,
                role: user.role,
                political_role: user.political_role,
                wants_email: user.wants_email

            }, {
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem('access_token')
                },
            })
                .then((response) => {
                    commit("SET_USER_EDITED", true);
                    commit("SET_USER_ERROR", "");
                    setTimeout(() => {
                        commit("SET_USER_EDITED", false);
                    }, 1500)
                })
                .catch(error => {
                    commit("SET_USER_ERROR", error.response.data);
                })
        }
    },
    async deleteUser(commit, id) {
        await axios.delete(`${url}/api/user/${id}`, {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + localStorage.getItem('access_token')
            },
        })
            .then(response => {
                router.back();
            })
            .catch(err => {
                console.log(err)
            })
    },
    async getNotifications({ commit }) {
        await axios.get(`${url}/api/notifications`, {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + localStorage.getItem('access_token')
            }
        }).then(response => {
            commit("SET_NOTIFICATIONS", response.data)
        }).catch(error => {
            console.log(error.message);
        });
    }
};

const mutations = {
    SET_USER_ARTICLES(state, userArticles) {
        state.userArticles = userArticles;
    },
    SET_USER(user, token) {
        state.user = user;
        state.access_token = token;
    },
    SET_USER_TO_EDIT_ADMIN(state, userToEdit) {
        state.user_to_edit = userToEdit;
    },
    SET_USER_ROLES(state, roles) {
        state.user_roles = roles;
    },
    SET_USER_CREATED(state, bool) {
        state.userCreatedSuccess = bool;
    },
    SET_USER_EDITED(state, bool) {
        state.editUserSuccess = bool;
    },
    SET_CURRENT_USER(state, user) {
        state.currentUser = user;
    },
    SET_USER_EDITED_RIGHTS_SUCCESS(state, bool) {
        state.editedPermissions = bool;
    },
    SET_NOTIFICATIONS(state, notifications) {
        state.notifications = notifications;
    },
    SET_PASSWORD_FORGOT_SUCCESS(state, bool) {
        state.forgotPasswordSuccess = bool;
    },
    SET_USER_ERROR(state, error) {
        state.user_error = error;
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}

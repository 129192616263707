import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Forgot from '../views/ForgotPassword.vue'
import ResetPassword from '../views/ResetPassword.vue'
import AddUser from "../views/AddUser";
import AddItem from "../views/AddItem";
import EditItem from "../views/EditItem";
import EditUser from "../views/EditUser";
import AdminDashboard from "../views/AdminDashboard";
import ExpertDashboard from "../views/ExpertDashboard";
import Detail from "../views/Detail"
import DetailPublic from "../views/DetailPublic"
import Menu from "../views/Menu";
import Category from "../views/Category";
import Categories from "../views/Categories";
import AddCategory from "../views/AddCategory";
import AskQuestion from "../views/AskQuestion";
import AnsQuestion from "../views/AnswerQuestion";
import AdminArticle from "../views/AdminArticle";
import EditCategory from "../views/EditCategory";
import PageNotFound from "../views/PageNotFound"
import UserAskedQuestions from "../views/UserAskedQuestions"
import AdminQuestions from "../views/admin/Questions"
import Questions from "../views/Questions"
import AdminCategories from "../views/admin/Categories"
import AdminArticles from "../views/admin/Articles"
import Notifications from "../views/admin/Notifications";
import MyAccount from "../views/MyAccount";
import MyArticles from "../views/MyArticles";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Menu,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/mijn-artikelen',
    name: 'my-articles',
    component: MyArticles,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/mijn-account',
    name: 'my-account',
    component: MyAccount,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/admin',
    name: 'AdminDashboard',
    component: AdminDashboard,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/expert',
    name: 'ExpertDashboard',
    component: ExpertDashboard,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/forgot-password',
    name: 'Forgot password',
    component: Forgot
  },
  {
    path: '/reset-password',
    name: 'Reset password',
    component: ResetPassword
  },
  {
    path: '/add-user',
    name: 'Add user',
    component: AddUser,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/add-item',
    name: 'Add item',
    component: AddItem,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/edit-item/:id',
    name: 'Edit item',
    component: EditItem,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/edit-user/:id',
    name: 'Edit user',
    component: EditUser,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/details/:id',
    name: 'Details',
    component: Detail,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/public/:id',
    name: 'DetailsPublic',
    component: DetailPublic
  },
  {
    path: '/category/:id',
    name: 'Category',
    component: Category,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/categories',
    name: 'Categories',
    component: Categories,
    props: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/add-category',
    name: 'Add category',
    component: AddCategory,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/ask-question',
    name: 'AskQuestion',
    component: AskQuestion,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/ans-question/:id',
    name: 'AnsQuestion',
    component: AnsQuestion,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/admin-article/:id',
    name: 'AdminArticle',
    component: AdminArticle,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/edit-category/:id',
    name: 'Edit category',
    component: EditCategory,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/asked-questions',
    name: 'UserAskedQuestions',
    component: UserAskedQuestions,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/questions',
    name: 'Questions',
    component: Questions,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/admin/questions',
    name: 'Admin questions',
    component: AdminQuestions,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/admin/categories',
    name: 'Admin categories',
    component: AdminCategories,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/admin/articles',
    name: 'Admin articles',
    component: AdminArticles,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/admin/notifications',
    name: 'Admin notifications',
    component: Notifications,
    meta: {
      requiresAuth: true
    }
  },
  { path: "*", component: PageNotFound }
]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (localStorage.getItem('access_token') === null) {
      next({
        path: '/login',
        params: { nextUrl: to.fullPath }
      })
    } else {
      next();
    }
  } else {
    next();
  }
})

export default router

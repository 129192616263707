import axios from 'axios';
import router from '../router';

// let url = 'https://ridb-api.retrii.dev'
let url = 'https://api.ridab.nl'
// let url = 'http://127.0.0.1:8000'

const state = {
    question: {},
    questions: [],
    searchQuestions: [],
    openQuestions: [],
    questionCreatedSuccess: false,
    questionUpdatedSuccess: false,
    questionDeletedSuccess: false,
    question_error: ""
};

const getters = {
    getQuestion: (state) => {
        return state.question;
    },
    getQuestions: (state) => {
        return state.questions;
    },
    getSearchQuestions: (state) => {
        return state.searchQuestions;
    },
    getQuestionCreatedSuccess: (state) => {
        return state.questionCreatedSuccess;
    },
    getQuestionUpdatedSuccess: (state) => {
        return state.questionUpdatedSuccess;
    },
    getDeletedUpdatedSuccess: (state) => {
        return state.questionDeletedSuccess;
    },
    getOpenQuestions: (state) => {
        return state.openQuestions;
    },
    getQuestionError: (state) => {
        return state.question_error;
    }
};

const actions = {
    async getQuestion({commit}, id) {
        await axios.get(`${url}/api/question/${id}`, {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + localStorage.getItem('access_token')
            }
        }).then((response) => {
            commit("SET_QUESTION", response.data)
        }).catch((error) => {
            console.log(error.message)
        })
    },
    async getAllQuestions({commit}) {
        await axios.get(`${url}/api/questions`, {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + localStorage.getItem('access_token')
            }
        }).then((response) => {
            commit("SET_QUESTIONS", response.data)
        }).catch((error) => {
            console.log(error.message)
        })
    },
    async getSearchQuestions({commit}, {search, sort}) {
        await axios.get(`${url}/api/search/questions?search=${search}&sort=${sort}`, {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + localStorage.getItem('access_token')
            }
        }).then((response) => {
            commit("SET_SEARCH_QUESTIONS", response.data)
        }).catch((error) => {
            console.log(error.message)
        })
    },
    async getAllOpenQuestions({commit}) {
        await axios.get(`${url}/api/open/questions`, {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + localStorage.getItem('access_token')
            }
        }).then((response) => {
            commit("SET_OPEN_QUESTIONS", response.data)
        }).catch((error) => {
            console.log(error.message)
        })
    },
    async createQuestion({ commit }, question ) {
        await axios({
            method: 'post',
            url: `${url}/api/question/create`,
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + localStorage.getItem('access_token')
            },
            data: {
                question: question.question,
                category_id: question.category_id
            }
        })
            .then((response) => {
                commit("SET_QUESTION_CREATED", true);
                commit("SET_QUESTION_ERROR", "");
                setTimeout(() => {
                    commit("SET_QUESTION_CREATED", false);
                    router.back();
                }, 1500)
            })
            .catch((error) => {
                commit("SET_QUESTION_ERROR", error.response.data);
            })
    },
    async answerQuestion({ commit }, question ) {
        await axios({
            method: 'patch',
            url: `${url}/api/question/update`,
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + localStorage.getItem('access_token')
            },
            data: {
                question: question.question,
                type: question.type,
                answer: question.answer
            }
        })
            .then((response) => {
                commit("SET_QUESTION_UPDATED", true);
                commit("SET_QUESTION_ERROR", "");
                setTimeout(() => {
                    commit("SET_QUESTION_UPDATED", false);
                    router.back();
                }, 1500)
            })
            .catch((error) => {
                commit("SET_QUESTION_ERROR", error.response.data);
            })
    },
    async deleteQuestion({commit}, id) {
        await axios.delete(`${url}/api/question/${id}`,{
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + localStorage.getItem('access_token')
            },
        })
            .then(response => {
                commit("SET_QUESTION_DELETED", true);
                setTimeout(() => {
                    commit("SET_QUESTION_DELETED", false);
                    router.back();
                }, 1500)
            })
            .catch(err =>{
                console.log(err)
            })
    }
};

const mutations = {
    SET_QUESTION(state, question){
        state.question = question;
    },
    SET_QUESTIONS(state, questions){
        state.questions = questions;
    },
    SET_SEARCH_QUESTIONS(state, questions){
        state.searchQuestions = questions;
    },
    SET_QUESTION_CREATED(state, bool){
        state.questionCreatedSuccess = bool;
    },
    SET_QUESTION_UPDATED(state, bool){
        state.questionUpdatedSuccess = bool;
    },
    SET_QUESTION_DELETED(state, bool){
        state.questionDeletedSuccess = bool;
    },
    SET_OPEN_QUESTIONS(state, questions){
        state.openQuestions = questions;
    },
    SET_QUESTION_ERROR(state, error) {
        state.question_error = error;
    }
};

export default {
    state,
    getters,
    actions,
    mutations
}

import axios from 'axios'
import router from '../router'

 // let url = 'https://ridb-api.retrii.dev'
let url = 'https://api.ridab.nl'
// let url = 'http://127.0.0.1:8000'


const state = {
    users: []
};


const getters = {
    getUsers: (state) => {
        return state.users;
    }
};


const actions = {
    async getAllUsers ({commit}){
        await axios.get(`${url}/api/users`, {
            headers: { 'Authorization': "Bearer " +localStorage.getItem('access_token')}
        })
            .then((response) =>{
                commit("SET_USERS", response.data)
            })
            .catch((err) =>{
                console.log(err);
            })
    }
};

const mutations = {
    SET_USERS(state, users) {
        state.users = users;
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}

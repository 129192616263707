<template>
  <b-form-group label="Filter op">
    <b-form-checkbox-group
        size="lg"
        id="checkbox-types"
        v-model="selected"
        :options="options"
        stacked
        @change="change"
    ></b-form-checkbox-group>
  </b-form-group>
</template>

<script>
export default {
  name: "Filter",
  props: ["options"],
  data() {
    return {
      selected: []
    }
  },
  methods: {
    change() {
      console.log(selected)
      this.$emit('input', this.content = selected)
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <div id="admin">
    <b-container fluid>
      <b-row class="justify-content-md-center">
        <b-col style="min-width: 210px;">
          <BackButton text="Terug naar admin" />
          <h2 class="p-0 my-5">Alle thema's</h2>
        </b-col>
        <Button class="button-create h-25 mr-3" style="margin-top: 120px;"  @click.native="$router.push({name: 'Add category'})" placeholder="Thema aanmaken"/>

        <b-col lg="12">
          <div class="tile">
            <p><strong>Thema's</strong></p>
            <TextField @keyup.native="searchInCategory" @change.native="searchInCategory" v-model="searchedCategory" class="admin-input" icon="search" placeholder="Zoeken"/>
            <div class="table-responsive">
              <table class="table" style="color: var(--default-text) !important;">
                <thead>
                <tr>
                  <th class="no-top-border" scope="col">Id</th>
                  <th class="no-top-border" scope="col">Naam</th>
                  <th class="no-top-border" scope="col">Sub thema</th>
                  <th class="no-top-border" scope="col">Actie</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="category in this.categories" :key="category.id" v-if="category.parent_id === null">
                  <td scope="row"><b>{{category.id}}</b></td>
                  <td>{{category.name}}</td>
                  <td style="color: #007bff">
                    <p style="color: #007bff" class="sub_theme m-0 p-0" v-for="sub in categories" :key="sub.id">
                      <router-link v-if="sub.parent_id === category.id" style="color: #007bff !important;" :to="`/edit-category/${sub.id}`">{{sub.name}}</router-link>
                    </p>
                  </td>
                  <td><Button @click.native="$router.push({name: 'Edit category', params: { id: category.id}})" placeholder="Bewerken"/></td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<style scoped>
</style>

<script>
import Button from "../../components/Button";
import TextField from "../../components/TextField";
import ArrowRight from "../../components/icons/ArrowRight";
import BackButton from "../../components/BackButton";
export default {
  name: "AdminCategories",
  components: {BackButton, ArrowRight, TextField, Button},
  data() {
    return {
      searchedCategory: "",
      categories: []
    }
  },
  methods: {
    async searchInCategory(){
      let categoriesStart = this.$store.getters['getCategories'].categories;
      if(this.searchedCategory === ""){
        await this.$store.dispatch("getAllCategories");
        this.categories = this.$store.getters['getCategories'];
      }else{
        this.categories = categoriesStart.filter((category) => {
          return category.name.toLocaleLowerCase().includes(this.searchedCategory.toLocaleLowerCase());
        });
      }
    },
    async getCategories() {
      await this.$store.dispatch("getAllCategories", {search: "", tags: ""});
      this.categories = this.$store.getters['getCategories'].categories;
    },
    async getSubCategories(parent_id) {
      let subs = this.$store.getters['getCategories'];
      subs.filter(sub => {
        return sub.parent_id === parent_id
      });
      return subs;
    }
  },
  async created(){
    if(localStorage.getItem('access_token')){
      await this.$store.dispatch("getCurrentUser");
      if((this.$store.getters['getCurrentUser'].roles[0].name !== "super-admin")){
        await this.$router.push("/");
      }else{
        await this.$store.dispatch("getAllCategories", {search: "", tags: "", sort: "desc"});
        this.categories = this.$store.getters['getCategories'].categories;
      }
    }
  },
}
</script>

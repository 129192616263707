<template>
  <b-container fluid id="admin">

    <BackButton class="mb-5 ml-1" text="Terug naar overzicht" />

    <div class="tile">
      <p><strong>Vragen</strong></p>
      <div class="table-responsive">
        <table class="table ">
          <thead>
          <tr>
            <th class="no-top-border" scope="col">Id</th>
            <th class="no-top-border" scope="col">Vraag</th>
            <th class="no-top-border" scope="col">Aangemaakt op</th>
            <th class="no-top-border" scope="col">Actie</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="question in this.questions">
            <th scope="row">{{question.id}}</th>
            <td>{{question.question}}</td>
            <td>{{question.created_at}}</td>
            <td><Button @click.native="$router.push({name: 'AnsQuestion', params: { id: question.id}})" placeholder="Bekijken"/></td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </b-container>
</template>

<script>
import Button from "../components/Button";
import BackButton from "../components/BackButton";

export default {
name: "UserAskedQuestions",
  components:{
    BackButton,
    Button
  },
  data(){
    return{
      questions: ""
    }
  },
  async created() {
    await this.$store.dispatch("getAllQuestions");
    this.questions =  this.$store.getters['getQuestions'];
  }
}
</script>


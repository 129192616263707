<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.9263 10L11.9631 16.9632L4.99993 10" stroke="var(--default-text)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
name: "Chevrondown"
}
</script>

<style scoped>

</style>

<template>
  <div id="fileinput">
    <b-form-file :accept="types" @change="onFileChange"  :multiple="multiple" class="file-input" @input="handleInput" v-model="files" :state="Boolean(files)" :placeholder="placeholder" drop-placeholder="Drop file here..."></b-form-file>
  </div>
</template>

<script>
export default {
  name: "FileInput",
  data() {
    return {
      files: []
    }
  },
  props: ['placeholder', 'types', 'multiple'],
  methods: {
    onFileChange(e) {
      this.$emit('fileChange', this.content = e)
    },
    handleInput (e) {
      console.log(e)
      this.$emit('input', this.content = e)
    }
  }
}
</script>
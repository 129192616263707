<template>
  <div class="sources">
    <div class="source mt-3 ml-3" v-for="source in sources">{{ source }}
      <Cross @click.native="remove(source)"/>
    </div>
  </div>
</template>

<script>
import Chevrondown from "./icons/Chevrondown";
import Cross from "./icons/Cross";
export default {
  name: "SourceList",
  data() {
    return {
    }
  },
  components: {Cross, Chevrondown},
  props: ["sources"],
  methods: {
    remove(source) {
      let id = this.sources.indexOf(source);
      this.sources.splice(id, 1);
    }
  }
}
</script>
<template>
  <svg width="24" height="24" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.25 14V14.3107L13.4697 14.5303L15.1893 16.25H0.810661L2.53033 14.5303L2.75 14.3107V14V9C2.75 6.18156 4.21828 3.99138 6.67291 3.4098L7.25 3.27306V2.68V2C7.25 1.58421 7.58421 1.25 8 1.25C8.41579 1.25 8.75 1.58421 8.75 2V2.68V3.27259L9.32651 3.40966C11.7722 3.99115 13.25 6.19222 13.25 9V14ZM8.99726 18.75C8.76827 19.0529 8.40537 19.25 8 19.25C7.58969 19.25 7.22724 19.0533 6.99971 18.75H8.99726Z" stroke="var(--default-text)" stroke-width="1.5"/>
  </svg>
</template>

<script>
export default {
  name: "Notification"
}
</script>

<style scoped>

</style>
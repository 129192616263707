<template>
  <div id="article" @click="singleQuestion(question.id)">
    <h3><b>{{ question.question }}</b></h3>
    <div class="creation">
      <div class="creator">
        <Person/> {{ question.user.name }}
      </div>
      <div class="date">
        <Calendar/> {{ question.created_at }}
      </div>
    </div>
  </div>
</template>

<script>
import Person from "../components/icons/Person";
import Calendar from "../components/icons/Calendar";
import router from "../router";
export default {
  name: "Question",
  components: {Person, Calendar},
  props: ["question"],
  methods: {
    singleQuestion(id) {
      router.push("/ans-question/" + id);
    }
  }
}
</script>
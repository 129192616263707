<template>
  <b-container fluid>
        <form @submit.prevent="search" class="search mb-5">
          <b-row>
            <b-col lg="5" md="6" class="searchField p-0 m-0">
              <p class="searchLabel">Zoeken op tekst</p>
              <TextField v-model="searchTerm" placeholder="Zoeken.." type="text" icon="search" />
            </b-col>

            <b-col lg="5" md="6" class="searchField p-0 m-0">
              <p class="searchLabel tags-input-label">Zoeken op labels</p>
              <vue-tags-input id="tags-input" placeholder="Zoeken.." v-model="tag" :tags="tags" @tags-changed="newTags => tags = newTags" />
            </b-col>

            <b-col lg="2" clas=" p-0 m-0">
              <Button type="submit" placeholder="Zoeken" />
            </b-col>
          </b-row>
        </form>
  </b-container>
</template>

<script>
import TextField from "../components/TextField";
import Button from "../components/Button";
import VueTagsInput from '@johmun/vue-tags-input';

export default {
  name: "SearchBar",
  components: {Button, TextField, VueTagsInput},
  data() {
    return {
      searchTerm: "",
      tag: "",
      tags: []
    }
  },
  props: {
    method: {type: Function}
  },
  methods: {
    search() {
      this.$emit('search', {value: this.searchTerm, tags: this.tags});
    }
  }
}
</script>

<!-- <style src="vue-multiselect/dist/vue-multiselect.min.css"></style> -->
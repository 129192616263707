<template>
  <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="2.1499" y="3" width="21.4983" height="19" rx="3" stroke="var(--default-text)" stroke-width="2"/>
    <path d="M7.52441 1V3" stroke="var(--default-text)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M18.2737 1V3" stroke="var(--default-text)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M2.1499 8H23.6482" stroke="var(--default-text)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M8.06177 13H10.2116" stroke="var(--default-text)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M15.5862 13H17.736" stroke="var(--default-text)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M8.06177 17H10.2116" stroke="var(--default-text)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M15.5862 17H17.736" stroke="var(--default-text)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
  export default {
    name: "Calendar"
  }
</script>
<template>
  <div id="article" @click="singleArticle(article.id)">
    <p><span v-if="article.layer_of_goverment">{{ article.layer_of_goverment }}</span><span
        v-if="article.layer_of_goverment"> - </span>
      <span v-if="article.type">{{ article.type }}</span>
    </p>
    <div v-if="article.accepted !== null" class="icon-container">
      <svg v-if="article.accepted" class="accepted" xmlns="http://www.w3.org/2000/svg" height="2em" viewBox="0 0 448 512">
        <path
          d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
      </svg>
      <svg v-else class="denied" xmlns="http://www.w3.org/2000/svg" height="2em" viewBox="0 0 384 512">
        <path
          d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
      </svg>
    </div>
    <h1 class="title">{{ article.title }}</h1>
    <p class="summary">{{ article.description }}</p>
    <p v-for="sub in article.Tags" class="label">{{ sub.name.nl }}</p>
    <div class="creation">
      <div class="creator">
        <Person /> {{ article.creator.name }}
      </div>
      <div class="date">
        <Calendar /> {{ article.created_at }}
      </div>
    </div>
  </div>
</template>

<script>
import Person from "../components/icons/Person";
import Calendar from "../components/icons/Calendar";
import Cross from "../components/icons/Cross";
import router from "../router";
export default {
  name: "Article",
  components: { Person, Calendar, Cross },
  props: ["article"],
  methods: {
    singleArticle(id) {
      router.push("/details/" + id);
    }
  }
}
</script>

<style scoped>  .icon-container {
    position: absolute;
    right: 50px;
    top: 20px;
  }

  .icon-container .denied {
    fill: red;
  }

  .icon-container .accepted {
    fill: green;
  }
</style>
<template>
    <div id="my-items">
        <b-container>
            <div class="pb-5 pt-5 d-flex justify-content-between">
                <h1>Mijn artikelen</h1>
                <Button
                    v-if="this.$store.getters['getCurrentUser'].roles[0].name === 'super-admin' || this.$store.getters['getCurrentUser'].roles[0].name === 'expert' || this.$store.getters['getCurrentUser'].roles[0].name === 'gemeenteraadslid-plus' || this.$store.getters['getCurrentUser'].roles[0].name === 'statenleden-plus'"
                    @click.native="$router.push({ name: 'Add item' })" placeholder="Artikel toevoegen" />
            </div>

            <b-row>
                <b-col lg="12">
                    <div class="tile">
                        <p><strong>Artikelen</strong></p>

                        <TextField @keyup.native="searchInArticles" @change.native="searchInArticles"
                            v-model="searchedArticle" class="admin-input" icon="search" placeholder="Zoeken" />

                        <div class="table-responsive">
                            <table class="table " style="color: var(--default-text) !important;">
                                <thead>
                                    <tr>
                                        <th class="no-top-border" scope="col">Id</th>
                                        <th class="no-top-border" scope="col">Naam</th>
                                        <th class="no-top-border" scope="col">Type</th>
                                        <th class="no-top-border" scope="col">Auteur</th>
                                        <th class="no-top-border" scope="col">Aangemaakt op</th>
                                        <th class="no-top-border" scope="col">Actie</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="article in this.$store.getters['getUserArticles'] ">
                                        <th scope="row">{{ article.id }}</th>
                                        <td>{{ article.title }}</td>
                                        <td>{{ article.type }}</td>
                                        <td>{{ article.creator.name }}</td>
                                        <td>{{ article.created_at }}</td>
                                        <td class="p-2">
                                            <Button
                                                @click.native="$router.push({ name: 'Details', params: { id: article.id } })"
                                                class="mr-3" placeholder="Bekijken" />
                                            <Button
                                                @click.native="$router.push({ name: 'Edit item', params: { id: article.id } })"
                                                class="m-0" placeholder="Bewerken" />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import Button from "../components/Button";

export default {
    components: {
        Button
    },
    async created() {
        await this.$store.dispatch('getMyArticles');
    }
}
</script>

<style>
#my-items {
    background-color: var(--rik-bg) !important;
    height: 100vh;
}

h1 {
    margin: 0 !important;
}
</style>
<template>
  <div id="add-category" class="container-fluid m-0 p-0 w-100">
    <div class="content">
      <div class="wrapper">
        <BackButton text="Terug naar overzicht" href="/admin" />
        <h1>Thema aanmaken</h1>
        <div class="tile">
          <b-form @submit.prevent="createCategory">
            <div v-if="this.$store.getters['getCategoryError']" class="error ml-2">Niet alle velden zijn correct ingevuld</div>
            <div v-if="this.$store.getters['getCategoryCreatedSuccess']" class="success">Thema aangemaakt</div>
            <TextField class="category-name mt-4" v-model="name" placeholder="Naam" type="text" />
            <TextArea class="category-descr" v-model="description" placeholder="Beschrijving" />
            <img class="image" :src="url !== '' ? url : image" :alt="image.name"/>
            <FileInput @fileChange="onFileChange" class="category-image mb-5" v-model="image" placeholder="Kies een foto" types=".png, .jpeg, .jpg" :multiple="false" />
            <Select :options="options" selectedValue="main-category" placeholder="Kies een thema" v-model="type" />
            <Select v-if="type === 'sub-category'" placeholder="Kies een hoofd-thema" :options="categories" selectedValue="Kies een hoofd-thema" v-model="parent" />
            <Button type="submit" class="w-50" placeholder="Thema aanmaken" />
          </b-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BackButton from "../components/BackButton";
import TextField from "../components/TextField";
import TextArea from "../components/TextArea";
import FileInput from "../components/FileInput";
import Button from "../components/Button";
import Select from "../components/Select";
export default {
  name: "AddCategory",
  components: {Select, Button, FileInput, TextArea, TextField, BackButton},
  data() {
    return {
      name: "",
      description: "",
      image: "",
      url: "",
      type: "",
      parent: "",
      options: [
        {text: "Hoofd thema", value: "main-category"},
        {text: "Sub thema", value: "sub-category"}
      ],
      categories: []
    }
  },
  methods: {
    onFileChange(e) {
      const file = e.target.files[0];
      this.url = URL.createObjectURL(file);
    },
    createCategory() {
      let category = {
        name: this.name,
        description: this.description,
        image: this.image,
        parent_id: this.parent
      }
      this.$store.dispatch("createCategory", category);
    }
  },
  async created(){
    if(localStorage.getItem('access_token')){
      await this.$store.dispatch("getCurrentUser");

      if((this.$store.getters['getCurrentUser'].roles[0].name !== "super-admin")){
        await this.$router.push("/");
      } else {
        await this.$store.dispatch("getAllCategories", {search: "", tags: "", sort: ""});

        this.$store.getters['getCategories'].categories.forEach(category => {
          if (category.parent_id === null) {
            this.categories.push({text: category.name, value: category.id});
          }
        });
      }
    }
  }
}
</script>

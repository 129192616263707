<template>
  <div id="theme" class="container-fluid">
    <b-row class="justify-content-md-center">
      <b-col lg="10" md="12">

        <h1 class="theme-title">Alle vragen</h1>

        <form @submit.prevent="search" class="search">
          <b-row>
            <b-col cols="6" class="searchField m-0 p-0">
              <p class="searchLabel">Zoeken op tekst</p>
              <TextField style="width: 35vw" v-model="searchTerm" placeholder="Zoeken.." type="text" icon="search" />
            </b-col>
            <b-col lg="6">
              <Button type="submit" placeholder="Zoeken" />
            </b-col>
          </b-row>
          <div class="searchField">
          </div>
        </form>

        <b-row class="header my-3" align-h="between">
          <b-col class="m-0 p-0" lg="3"><p class="font-weight-bold">Aantal resultaten: {{ questions.length }}</p></b-col>
          <b-col class="m-0 p-0" cols="auto"><SortButton default="desc" @change="search"/> </b-col>
        </b-row>

        <b-row>
          <b-col lg="5">
            <div class="filters">
              <b-form-group label="Filter op thema">
                <b-form-checkbox-group
                    size="lg"
                    id="checkbox-sub"
                    v-model="categoryFilters"
                    :options="this.$store.getters['getCategoryList']"
                    stacked
                    @change="refreshResults"
                ></b-form-checkbox-group>
              </b-form-group>
            </div>
          </b-col>
          <b-col lg="7">
            <div class="questions">
              <Question v-for="question in results" :key="question.id" :question="question" />
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>
<style>
.sort:hover {
  cursor: pointer;
}
</style>
<script>
import SearchBar from "../components/SearchBar";
import Article from "../components/Article";
import Chevrondown from "../components/icons/Chevrondown";
import Checkbox from "../components/Checkbox";
import Filter from "../components/Filter";
import Question from "./Question";
import TextField from "../components/TextField";
import Button from "../components/Button";
import Chevronup from "../components/icons/Chevronup";
import SortButton from "../components/SortButton";
export default {
  name: "Questions",
  components: {SortButton, Chevronup, Button, TextField, Question, Filter, Checkbox, Chevrondown, Article, SearchBar},
  data() {
    return {
      categoryFilters: [],
      questions: [],
      results: [],
      searchTerm: ""
    }
  },
  methods: {
    async search(sort) {
      await this.$store.dispatch("getSearchQuestions", {search: this.searchTerm, sort: sort});
      this.questions = this.$store.getters['getSearchQuestions'].questions;
      this.searchTerm = "";
      this.refreshResults()
    },
    refreshResults() {
      this.results = [];
      if (this.categoryFilters.length > 0) {
        this.questions.forEach(question => {
          this.categoryFilters.forEach(filter => {
            if (question.category_id === filter) {
              this.results.push(question);
            }
          })
        })
        return;
      }
      this.results = this.questions;
    }
  },
  async created() {
    if(localStorage.getItem('access_token')){
      await this.$store.dispatch("getCurrentUser");
      if((this.$store.getters['getCurrentUser'].roles[0].name !== "user")){
        await this.$router.push("/");
      }else {
        await this.$store.dispatch("getSearchQuestions", {search: "", sort: "desc"});
        this.questions = this.$store.getters['getSearchQuestions'].questions;
        await this.$store.dispatch("getListCategories");
        this.refreshResults();
      }
    }
  }
}
</script>

<style scoped>
#theme {
  background-color: var(--default-background) !important;
}
</style>

<template>
  <div>
    <h2 style="text-align: center; color: var(--default-text); margin-top: 150px;">Pagina niet gevonden!</h2>
  </div>
</template>

<script>
export default {
  name: "404"
}
</script>

<style scoped>

</style>

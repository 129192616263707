<template>
  <div id="theme" class="container-fluid">
    <b-row class="justify-content-md-center">
      <b-col lg="10" md="12">

        <h1 class="theme-title">Resultaten van uw thema's</h1>

        <SearchBar @search="searchCategory" />

        <b-row class="header" align-h="between">
          <b-col class="m-0 p-0" lg="3"><p class="font-weight-bold">Aantal resultaten: {{ this.articles.length }}</p></b-col>
          <b-col class="m-0 p-0" cols="auto"><SortButton default="desc" @change="sortResults" /> </b-col>
        </b-row>

        <b-row>
          <b-col lg="5">
            <div class="filters">
              <b-form-group label="Filter op">
                <b-form-checkbox-group
                    size="lg"
                    id="checkbox-types"
                    v-model="type_filters"
                    :options="types"
                    stacked
                    @change="getResults"
                ></b-form-checkbox-group>
              </b-form-group>
              <b-form-group label="Filter op thema">
                <b-form-checkbox-group
                    size="lg"
                    id="checkbox-sub"
                    v-model="cat_filters"
                    :options="getCategoriesSelect()"
                    stacked
                    @change="getResults"
                ></b-form-checkbox-group>
              </b-form-group>
            </div>
          </b-col>
          <b-col lg="7">
            <div class="articles">
              <Article v-for="article in this.articles" :key="article.id" :article="article" />
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>




  </div>
</template>

<script>
import SearchBar from "../components/SearchBar";
import Article from "../components/Article";
import Chevrondown from "../components/icons/Chevrondown";
import Checkbox from "../components/Checkbox";
import Filter from "../components/Filter";
import SortButton from "../components/SortButton";
export default {
  name: "Categories",
  components: {SortButton, Filter, Checkbox, Chevrondown, Article, SearchBar},
  props: ["search", "tags"],
  data() {
    return {
      categories: {},
      articles: [],
      sort: "desc",
      type_filters: [],
      cat_filters: [],
      types: [
        {text: "Motie", value: "motie"},
        {text: "Artikel", value: "artikel"},
      ],
    }
  },
  methods: {
    getResults() {
      this.articles = [];
      if (this.type_filters.length > 0 || this.cat_filters.length > 0) {
        this.categories.articles.forEach(article => {
          this.type_filters.forEach(filter => {
            if (article.type === filter) {
              this.articles.push(article);
            }
          });
          this.cat_filters.forEach(cat => {
            if (article.cat.name === cat) {
              this.articles.push(article)
            }
          })
        });
        return;
      }
      this.articles = this.categories.articles;
      this.articles.forEach(article => {
        if (article.creator === null) {
          article.creator = {name: "verwijderd"};
        }
      });
    },
    sortResults(sort) {
      this.sort = sort;
      this.searchCategory({value: "", tags: []})
    },
    async searchCategory(args) {
      console.log(args)
      let tags = [];
      args.tags.forEach(tag => {
        tags.push(tag.text);
      });
      tags = tags.join(",");
      await this.$store.dispatch("getAllCategories", {search: args.value, tags: tags, sort: this.sort });
      this.categories = this.$store.getters['getCategories'];
      this.getResults()
    },
    getCategoriesSelect() {
      let categories = [];
      this.categories.categories.forEach(category => {
        categories.push(category.name) // .split("/")[0]
      })
      return categories
    }
  },
  async beforeCreate() {
    console.log(this.sort)
    await this.$store.dispatch("getAllCategories", {search: this.$route.query.search, tags: this.$route.query.tags, sort: "desc"});
    this.categories = this.$store.getters['getCategories'];
    this.getResults();
  }
}
</script>

<style scoped>
.container-fluid {
  background-color: var(--default-background) !important;
  min-height: 0;
}
</style>

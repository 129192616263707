import { render, staticRenderFns } from "./Chevronup.vue?vue&type=template&id=ed4e4890&scoped=true&"
import script from "./Chevronup.vue?vue&type=script&lang=js&"
export * from "./Chevronup.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ed4e4890",
  null
  
)

export default component.exports
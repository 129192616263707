import axios from 'axios';
import router from '../router';

// let url = 'https://ridb-api.retrii.dev'
let url = 'https://api.ridab.nl'
// let url = 'http://127.0.0.1:8000'

const state = {
    article: {},
    publicArticle: {},
    articles: [],
    openArticles: [],
    itemCreatedSuccess: false,
    itemUpdatedSuccess: false,
    deletedSuccess: false,
    itemPublic: false,
    types: [
        { value: 'motie', text: 'Motie' },
        { value: 'artikel', text: 'Artikel' },
        { value: 'amendement', text: 'Amendement' },
        { value: 'schriftelijke-vragen', text: 'Schriftelijke vragen' },
        { value: 'bijdragen/speeches', text: 'Bijdragen/speeches' },
    ],
    article_error: "",
    madeComment: false
};

const getters = {
    getArticle: (state) => {
        return state.article;
    },
    getPublicArticle: (state) => {
        return state.publicArticle;
    },
    getArticles: (state) => {
        return state.articles;
    },
    getOpenArticles: (state) => {
        return state.openArticles;
    },
    getItemCreatedSuccess: (state) => {
        return state.itemCreatedSuccess;
    },
    getItemUpdatedSuccess: (state) => {
        return state.itemUpdatedSuccess;
    },
    getItemDeletedSuccess: (state) => {
        return state.deletedSuccess;
    },
    getItemPublic: (state) => {
        return state.itemPublic;
    },
    getArticleTypes: (state) => {
        return state.types;
    },
    getArticleError: (state) => {
        return state.article_error;
    }
};

const actions = {
    async getArticle({ commit }, id) {
        await axios.get(`${url}/api/article/${id}`, {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + localStorage.getItem('access_token')
            }
        }).then((response) => {
            commit("SET_ARTICLE", response.data)
        }).catch((error) => {
            console.log(error.message)
        })
    },
    async getPublicArticle({ commit }, id) {
        await axios.get(`${url}/api/article/public/${id}`, {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            }
        }).then((response) => {
            commit("SET_PUBLIC_ARTICLE", response.data)
        }).catch((error) => {
            router.push("/*")
        })
    },
    async getAllArticles({ commit }) {
        await axios.get(`${url}/api/articles`, {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + localStorage.getItem('access_token')
            }
        }).then((response) => {
            commit("SET_ARTICLES", response.data)
        }).catch((error) => {
            console.log(error.message)
        })
    },
    async getAllOpenArticles({ commit }) {
        await axios.get(`${url}/api/open/articles`, {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + localStorage.getItem('access_token')
            }
        }).then((response) => {
            commit("SET_OPEN_ARTICLES", response.data)
        }).catch((error) => {
            console.log(error.message)
        })
    },
    async createArticle({ commit }, article) {
        const formData = new FormData();
        formData.append('title', article.title)
        formData.append('type', article.type)
        formData.append('description', article.description)
        formData.append('category_id', article.category_id)
        formData.append('category_id', article.category_id)
        formData.append('public', false)
        formData.append('layer_of_goverment', article.layerOfGoverment)
        formData.append('layer_of_township', article.layerOfTownship)

        if (article.accepted === true) {
            formData.append('accepted', 1)
        } else {
            formData.append('accepted', 0)
        }

        if (article.sources !== undefined) {
            formData.append('sources', article.sources.join(","))
        }
        if (article.mediaFiles !== undefined) {
            for (let i = 0; i < article.mediaFiles.length; i++) {
                let file = article.mediaFiles[i];
                formData.append('mediaFiles[' + i + ']', file);
            }
        }
        let config = {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem('access_token'),
                'Content-Type': 'multipart/form-data',
                "Accept": "application/json"
            },
        }
        await axios.post(`${url}/api/article/create`, formData, config)
            .then(() => {
                commit("SET_ITEM_CREATED", true);
                commit("SET_ARTICLE_ERROR", "")
                setTimeout(() => {
                    commit("SET_ITEM_CREATED", false);
                    router.back();
                }, 1500)
            })
            .catch((error) => {
                commit("SET_ARTICLE_ERROR", error.response.data)
            })
    },
    async updateArticle({ commit }, article) {
        const formData = new FormData();
        formData.append('title', article.title)
        formData.append('type', article.type)
        formData.append('public', article.public)
        // formData.append('tags', article.Tags)
        formData.append('description', article.description)
        formData.append('category_id', article.category_id)
        formData.append('layer_of_goverment', article.layer_of_goverment)
        formData.append('layer_of_township', article.layer_of_township)


        if (article.accepted === true) {
            formData.append('accepted', 1)
        } else {
            formData.append('accepted', 0)
        }


        if (article.sources !== null && article.sources !== undefined) {
            formData.append('sources', article.sources.join(","))
        }
        // if (article.Tags !== null && article.Tags !== undefined) {
        //     console.log(article.Tags)
        //     formData.append('tags', article.Tags.join(","))
        // }

        if (article.files !== undefined) {
            for (let i = 0; i < article.files.length; i++) {
                let file = article.files[i];
                formData.append('mediaFiles[' + i + ']', file);
            }
        }
        let config = {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem('access_token'),
                'Content-Type': 'multipart/form-data',
                "Accept": "application/json"
            },
        }
        await axios.post(`${url}/api/article/${article.id}`, formData, config)
            .then(() => {
                commit("SET_ITEM_UPDATED", true);
                commit("SET_ARTICLE_ERROR", "")
                setTimeout(() => {
                    commit("SET_ITEM_UPDATED", false);
                    router.back();
                }, 1500)
            })
            .catch((error) => {
                commit("SET_ARTICLE_ERROR", error.response.data)
            })
    },
    async deleteArticle({ commit }, id) {
        let config = {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem('access_token'),
                "Accept": "application/json"
            },
        }
        await axios.delete(`${url}/api/article/${id}`, config)
            .then(() => {
                commit("SET_ITEM_DELETED", true);
                setTimeout(() => {
                    commit("SET_ITEM_DELETED", false);
                    router.back();
                }, 1500)
            })
            .catch((error) => {
                console.log(error)
            })
    },
    async setArticleToPublic({ commit }, id) {
        let config = {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem('access_token'),
                "Accept": "application/json"
            },
        }
        await axios.post(`${url}/api/article/public/${id}`, { public: 1 }, config)
            .then(() => {
                commit("SET_ITEM_PUBLIC", true);
                setTimeout(() => {
                    commit("SET_ITEM_PUBLIC", false);
                }, 1500)
            })
            .catch((error) => {
                console.log(error)
            })
    },
    async comment({ commit }, comment) {
        let config = {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem('access_token'),
                "Accept": "application/json"
            },
        }
        await axios.post(`${url}/api/comment/`, { comment_text: comment.comment_text, article_id: comment.id, comment_id: comment.comment_id }, config)
            .then(() => {
                commit("SET_MADE_COMMENT", true);
                setTimeout(() => {
                    commit("SET_MADE_COMMENT", false);
                }, 1500)
            })
            .catch((error) => {
                console.log(error)
            })
    },
    async deleteComment({ commit }, id) {
        let config = {
            headers: {
                "Authorization": "Bearer " + localStorage.getItem('access_token'),
                "Accept": "application/json"
            },
        }
        await axios.delete(`${url}/api/comment/${id}`, config)
            .then(() => {
                // commit("SET_MADE_COMMENT", true);
                // setTimeout(() => {
                //     commit("SET_MADE_COMMENT", false);
                // }, 1500)
            })
            .catch((error) => {
                console.log(error)
            })
    }
};

const mutations = {
    SET_MADE_COMMENT(state, bool) {
        state.madeComment = bool;
    },
    SET_ARTICLE(state, article) {
        state.article = article;
    },
    SET_PUBLIC_ARTICLE(state, article) {
        state.publicArticle = article;
    },
    SET_ARTICLES(state, articles) {
        state.articles = articles;
    },
    SET_OPEN_ARTICLES(state, articles) {
        state.openArticles = articles;
    },
    SET_ITEM_CREATED(state, bool) {
        state.itemCreatedSuccess = bool;
    },
    SET_ITEM_UPDATED(state, bool) {
        state.itemUpdatedSuccess = bool;
    },
    SET_ITEM_DELETED(state, bool) {
        state.deletedSuccess = bool
    },
    SET_ITEM_PUBLIC(state, bool) {
        state.itemPublic = bool
    },
    SET_ARTICLE_ERROR(state, error) {
        state.article_error = error
    }
};

export default {
    state,
    getters,
    actions,
    mutations
}

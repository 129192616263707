<template>
  <b-container fluid id="edit-user">
    <div class="content">
      <BackButton href="/admin" text="Terug naar overzicht" />
      <h1 class="mt-5">{{ user.name }} </h1>
      <b-row class="m-0 p-0">
        <b-col lg="6" md="12" class="p-0">
          <form class="edit">
            <div v-if="this.$store.getters['getUserError']" class="error">Niet alle velden zijn correct ingevuld</div>
            <div class="success" v-if="this.$store.getters['getUserEditedSuccess']">Gebruiker bewerkt!</div>
            <TextField class="mb-4" type="text" placeholder="Naam" :value="user.name" v-model="user.name" />
            <TextField class="mb-4" type="text" placeholder="Email" :value="user.email" v-model="user.email" />
            <span v-if="passwordError" class="error">Het wachtwoord moet meer dan 16 karakters lang zijn</span>
            <TextField class="mb-4" type="password" placeholder="Wachtwoord" v-model="user.password" />
            <Select class="mb-4" placeholder="Kies een rol" v-model="user.role[0]" :selectedValue="user.role[0]"
              :options="roles" />
            <Select class="mb-4" placeholder="Kies een titel" :selectedValue="user.political_role"
              v-model="user.political_role" :options="titles" />
            <Checkbox class="mb-4" :is-checked="user.wants_email" v-model="user.wants_email"
              placeholder="Email ontvangen" />
            <Button @click.native="editUser" id="edit" class="mt-3 w-75" placeholder="Gebruiker bewerken" />
            <Button v-b-modal.modal id="delete" class="mt-3 w-75" placeholder="Gebruiker verwijderen" />

            <b-modal centered id="modal" ref="modal" hide-footer size="sm" title="Waarschuwing!">
              <p>Weet je zeker dat je deze gebruiker wil verwijderen?</p>
              <Button class="button-no" placeholder="Nee" @click.native="$refs['modal'].hide()"
                style="background-color: red;" />
              <Button class="button-yes" placeholder="Ja" @click.native="deleleteUser"
                style="background-color: var(--rik-orange)" />
            </b-modal>

          </form>
        </b-col>
        <b-col lg="6" md="12" class="p-0">
          <form @submit.prevent="update" class="permissions">
            <div class="success" v-if="this.$store.getters['getUserEditedPermissionsSuccess']">Rechten bewerkt!</div>
            <TextField @keyup.native="searchInRoles" @change.native="searchInRoles" v-model="searchedCategory"
              class="admin-input search-field" icon="search" placeholder="Zoeken" />
            <p class="ml-2 mt-2 mb-3">{{ categories.length }} resultaten</p>
            <div class="table-responsive">
              <b-table-simple borderless style="color: var(--default-text) !important;">
                <b-thead class="header">
                  <b-tr>
                    <b-th class="">Soort</b-th>
                    <b-th>Thema</b-th>
                    <b-th class="options">Rechten</b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr class="table-row" v-for="theme in categories" :key="theme.category_id">
                    <b-td v-if="theme.parent_id === null">Hoofd thema</b-td>
                    <b-td v-else>Sub thema</b-td>
                    <b-td>{{ theme.category_name }}</b-td>
                    <b-td class="options">
                      <Checkbox class="mb-4" @input="checked(theme)" :is-checked="theme.read" v-model="theme.read"
                        placeholder="Lezen" />
                      <Checkbox class="mb-4" @input="checked(theme)" :is-checked="theme.delete" v-model="theme.delete"
                        placeholder="Verwijderen" />
                      <Checkbox class="mb-4" @input="checked(theme)" :is-checked="theme.edit" v-model="theme.edit"
                        placeholder="Bewerken" />
                      <Checkbox @input="checked(theme)" :is-checked="theme.create" v-model="theme.create"
                        placeholder="Aanmaken" />
                    </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </div>

            <Button type="submit" class="mt-3 w-75" placeholder="Rechten bewerken" />
          </form>
        </b-col>
      </b-row>
    </div>
  </b-container>
</template>

<script>
import BackButton from "../components/BackButton";
import TextField from "../components/TextField";
import Select from "../components/Select";
import Button from "../components/Button";
import Checkbox from "../components/Checkbox";
import { required, email } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      searchedCategory: "",
      roles: this.$store.getters['getPermRoles'],
      categories: [],
      user: {},
      passwordError: false,
      titles: [
        { value: 'Statenlid', text: 'Statenlid' },
        { value: 'Senator', text: 'Senator' },
        { value: 'Tweede Kamer Lid', text: 'Tweede Kamer Lid' },
        { value: 'Medewerker RI', text: 'Medewerker RI' },
        { value: 'Gemeenteraadslid', text: 'Gemeenteraadslid' }
      ],
    }
  },
  methods: {
    checked(theme) {
      if (theme.parent_id !== null) {
        let parent = this.$store.getters['getUserRoles'].find(role => role.category_id === theme.parent_id);
        let childs = this.$store.getters['getUserRoles'].filter(role => role.parent_id === theme.parent_id);

        childs.forEach(child => {
          if (child.read) parent.read = true;
          else if (child.create) parent.create = true;
          else if (child.delete) parent.delete = true;
          else if (child.edit) parent.edit = true;
        });
      }
      if (theme.parent_id === null) {
        let childs = [];
        this.$store.getters['getUserRoles'].forEach(role => {
          if (role.parent_id === theme.category_id) {
            childs.push(role);
          }
        });
        childs.forEach(child => {
          child.read = theme.read === true;
          child.create = theme.create === true;
          child.delete = theme.delete === true;
          child.edit = theme.edit === true;
        })
      }
    },
    async searchInRoles() {
      let categoriesStart = this.$store.getters['getUserRoles'];
      if (this.searchedCategory === "") {
        this.categories = categoriesStart;
      } else {
        this.categories = categoriesStart.filter((category) => {
          return category.category_name.toLocaleLowerCase().includes(this.searchedCategory.toLocaleLowerCase());
        });
      }
    },
    async update() {
      await this.$store.dispatch("userRolesUpdate", { id: this.$route.params.id, roles: this.categories })
    },
    async deleleteUser() {
      this.$refs['modal'].hide();
      await this.$store.dispatch("deleteUser", this.$route.params.id);
    },
    async editUser() {
      let userToEdit = {
        id: this.$route.params.id,
        name: this.user.name,
        email: this.user.email,
        password: this.user.password,
        role: this.user.role[0],
        political_role: this.user.political_role,
        wants_email: this.user.wants_email
      }

      if (userToEdit.password !== undefined && userToEdit.password.length < 16) {
        this.passwordError = true;
        return;
      }

      await this.$store.dispatch("userUpdate", userToEdit)
        .then(() => {
          this.$store.dispatch("userGet", this.$route.params.id);
        })
    }
  },
  async created() {
    if (localStorage.getItem('access_token')) {
      await this.$store.dispatch("getCurrentUser");
      if ((this.$store.getters['getCurrentUser'].roles[0].name !== "super-admin")) {
        await this.$router.push("/");
      } else {
        await this.$store.dispatch("userGet", this.$route.params.id);
        await this.$store.dispatch("userRolesGet", this.$route.params.id);
        this.user = this.$store.getters['getUserToEdit'];
        this.categories = this.$store.getters['getUserRoles'];
      }
    }
  },
  components: { Checkbox, Button, Select, TextField, BackButton }
}
</script>


<template>
  <div id="login" class="container-fluid m-0 p-0 w-100" style="background-color: var(--default-background)">
    <b-row  class="m-0 p-0">
      <b-col class="image-logo m-0 p-0">
          <img class="bg" src="../assets/images/school_of_athens.jpg" alt="">
          <div class="content">
            <img class="logo" src="../assets/images/fvd-logo.png" alt="">
            <h1 class="header-text">Renaissance Instituut Kennisbank</h1>
          </div>
      </b-col>
      <b-col class="m-0 p-0" align-self="center">
        <b-form class="login-form" @submit.prevent="login">
          <h1 style="color: var(--default-text)">Inloggen</h1>
          <div v-if="empty_field" class="error">Niet alle velden zijn ingevuld</div>
          <div v-if="this.$store.getters['getUserError']" class="error">De inlog gegevens komen niet overeen</div>
          <div v-if="!this.$v.email.email" class="error">Dit is een ongeldig email adres</div>
          <TextField class="mb-4" type="text" placeholder="Email" v-model="email"/>
          <TextField type="password" placeholder="Wachtwoord" v-model="password"/>

          <a @click="$router.push('/forgot-password')" style="color: var(--default-text)">Wachtwoord vergeten?</a>
          <Button class="w-100" type="submit" placeholder="Log in"/>
        </b-form>
      </b-col>
    </b-row>
  </div>
</template>

<style>
  .header-text {
    color: #FFFFFF;
  }
</style>

<script>
import TextField from "../components/TextField";
import Button from "../components/Button";
import { required, email } from "vuelidate/lib/validators";

export default {
  name: "Login",
  components: {TextField, Button},
  data() {
    return {
      email: "",
      password: "",
      empty_field: false
    }
  },
  methods: {
    login() {
      if (!this.$v.email.required || !this.$v.password.required) {
        this.empty_field = true;
        return;
      }
      this.empty_field = false;

      if (!this.$v.$invalid) {
        this.$store.dispatch("userLogin", {email: this.email, password: this.password});
      }
    }
  },
  validations: {
    email: {
      required,
      email
    },
    password: {
      required
    }
  }
}
</script>

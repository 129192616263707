<template>
  <div id="admin">
    <b-container fluid>
      <b-row class="justify-content-md-center">

        <b-col>
          <BackButton text="Terug naar admin" href="/admin" />
          <h2 v-if="showOpenArticles" class="p-0 my-4">Openstaande artikelen</h2>
          <h2 v-if="!showOpenArticles" class="p-0 my-4">Alle artikelen</h2>
        </b-col>
        <Button v-if="showOpenArticles" class="button-create h-25 mr-3" style="margin-top: 90px;" @click.native="showAll" placeholder="Alle artikelen"/>
        <Button v-if="!showOpenArticles" class="button-create h-25 mr-3" style="margin-top: 90px;" @click.native="showOpen" placeholder="Alle openstaande artikelen"/>
        <Button class="button-create h-25 mr-3" style="margin-top: 90px;"  @click.native="$router.push({name: 'Add item'})" placeholder="Artikel toevoegen"/>

        <b-col lg="12">
          <div class="tile">
            <p><strong>Artikelen</strong></p>

            <TextField @keyup.native="searchInArticles" @change.native="searchInArticles" v-model="searchedArticle" class="admin-input" icon="search" placeholder="Zoeken"/>

            <div class="table-responsive">
              <table class="table " style="color: var(--default-text) !important;">
                <thead>
                <tr>
                  <th class="no-top-border" scope="col">Id</th>
                  <th class="no-top-border" scope="col">Naam</th>
                  <th class="no-top-border" scope="col">Type</th>
                  <th class="no-top-border" scope="col">Auteur</th>
                  <th class="no-top-border" scope="col">Goedgekeurd</th>
                  <th class="no-top-border" scope="col">Aangemaakt op</th>
                  <th class="no-top-border" scope="col">Actie</th>
                </tr>
                </thead>
                <tbody>
                <tr  v-for="article in articles">
                  <th scope="row">{{article.id}}</th>
                  <td>{{article.title}}</td>
                  <td>{{article.type}}</td>
                  <td>{{article.creator.name}}</td>
                  <td v-if="article.public === 0">Niet goedgekeurd</td>
                  <td v-else>Goed gekeurd</td>
                  <td>{{article.created_at}}</td>
                  <td class="p-2">
                    <Button @click.native="$router.push({name: 'AdminArticle', params: { id: article.id}})" class="mr-3" placeholder="Bekijken"/>
                    <Button @click.native="$router.push({name: 'Edit item', params: { id: article.id}})" class="m-0" placeholder="Bewerken"/>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Button from "../../components/Button";
import TextField from "../../components/TextField";
import ArrowRight from "../../components/icons/ArrowRight";
import BackButton from "../../components/BackButton";
export default {
  name: "AdminArticles",
  components: {BackButton, ArrowRight, TextField, Button},
  data() {
    return {
      searchedArticle: "",
      articles: [],
      showOpenArticles: true
    }
  },
  methods: {
    async searchInArticles(){
      let results = [];
      if (this.showOpenArticles) {
        await this.$store.dispatch("getAllOpenArticles");
        results = this.$store.getters['getOpenArticles'];
      } else {
        await this.$store.dispatch("getAllArticles");
        results = this.$store.getters['getArticles'];
      }
      if(this.searchedArticle === ""){
        this.articles = results;
      }else{
        this.articles = results.filter((article) => {
          return article.title.toLocaleLowerCase().includes(this.searchedArticle.toLocaleLowerCase());
        });
      }
    },
    async showOpen() {
      this.showOpenArticles = true;
      await this.$store.dispatch("getAllOpenArticles");
      this.articles =  this.$store.getters['getOpenArticles'];
      this.articles.forEach(article => {
        if (article.creator === null) {
          article.creator = {name: "verwijderd"};
        }
      });
    },
    async showAll(){
      this.showOpenArticles = false;
      await this.$store.dispatch("getAllArticles");
      this.articles = this.$store.getters['getArticles'];
      this.articles.forEach(article => {
        if (article.creator === null) {
          article.creator = {name: "verwijderd"};
        }
      });
    },
  },
  async created(){
    if(localStorage.getItem('access_token')){
      await this.$store.dispatch("getCurrentUser");
      if((this.$store.getters['getCurrentUser'].roles[0].name !== "super-admin")){
        await this.$router.push("/");
      }else{
        await this.$store.dispatch("getAllOpenArticles");
        this.articles =  this.$store.getters['getOpenArticles'];
        console.log(this.articles)
        this.articles.forEach(article => {
          if (article.creator === null) {
            article.creator = {name: "verwijderd"};
          }
        });
      }
    }
  },
}
</script>

<template>
  <b-container fluid id="detail">
    <b-row class="content justify-content-md-center">
      <b-col lg="5" sm="8">
        <h1 class="label mt-5">{{ article.title }}</h1>
        <div class="creation">
          <div class="creator">
            <Person />
            {{ article.creator.name }}
          </div>
          <div class="date">
            <Calendar />
            {{ article.created_at }}
          </div>
        </div>

        <div class="mt-5">
          <p> <strong>Locatie: </strong>{{article.layer_of_goverment}}</p>
        </div>

        <h3 class="label">Samenvatting</h3>
        <p>{{ article.description }}</p>

        <ul class="files">
          <li v-for="file in article.article_files" class="file">
            <a :href="file.source" class="link" target="_blank"><File /><span class="link-text">{{ file.source }}</span></a>
          </li>
        </ul>

        <h3 class="label" v-if="article.sources">Bronvermelding</h3>
        <ul class="sources">
          <li v-for="source in article.sources" class="source">
            <a :href="source" class="link" target="_blank">{{ source }}</a>
          </li>
        </ul>
        <p v-for="tag in article.Tags" :key="article.id" class="article_tag">{{ tag.name.nl }}</p>
        <ShareNetwork
            class="mt-3"
            style="display: block; color: var(--default-text) !important;"
            network="whatsapp"
            :url="'https://ridab.nl/public/' + article.public_id"
            :title="article.title">
          Deel op whatsapp
        </ShareNetwork>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import BackButton from "../components/BackButton";
import Person from "../components/icons/Person";
import Calendar from "../components/icons/Calendar";
import File from "../components/icons/File";
import router from "../router";
export default {
  components: {File, Calendar, Person, BackButton},
  data() {
    return {
      article: {}
    }
  },
  async created() {
    await this.$store.dispatch("getPublicArticle", this.$route.params.id);
    this.article = this.$store.getters['getPublicArticle'];

    if (this.article.creator === null) {
      this.article.creator = {name: "verwijderd"};
    }
    if (this.article.sources !== null) {
      this.article.sources = this.article.sources.split(",");
    }
  }
}
</script>

<style scoped>
  #detail {
    background-color: var(--default-background) !important;
  }
</style>


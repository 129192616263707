<template>
  <b-container fluid id="add-category">
    <div class="content">
      <div class="wrapper">
        <BackButton text="Terug naar overzicht" href="/admin" />
        <h1>Thema aanpassen</h1>
        <div class="tile">
          <b-form @submit.prevent="editCategory">
            <div v-if="this.$store.getters['getCategoryCreatedSuccess']" class="success">Thema aangepast</div>
            <div v-if="this.$store.getters['getCategoryDeletedSuccess']" class="success">Thema verwijderd</div>
            <div v-if="this.$store.getters['getCategoryError']" class="error">Niet alle velden zijn correct <ingevuld></ingevuld></div>
            <TextField class="category-name mt-4" v-model="category.name" placeholder="Naam" type="text" />
            <TextArea class="category-descr" v-model="category.description" placeholder="Beschrijving" />
            <img class="image" :src="url !== '' ? url : category.image" alt=""/>
            <FileInput @fileChange="onFileChange" class="category-image mb-5" v-model="category.image" placeholder="Kies een foto" types=".png, .jpeg, .jpg" :multiple="false" />
            <Select :options="options" selectedValue="main-category" v-model="type" />
            <Select v-if="type === 'sub-category'" placeholder="Kies een hoofd-thema" :options="categories" selectedValue="Kies een hoofd-thema" v-model="parent" />
            <Button type="submit" placeholder="Thema aanpassen" />
            <Button v-b-modal.modal style="display:block; margin-top: 20px; background-color: red;" placeholder="Thema verwijderen" />

            <b-modal centered id="modal" ref="modal" hide-footer size="sm" title="Waarschuwing!">
              <p>Weet je zeker dat je dit thema wil verwijderen?</p>
              <Button class="button-no" placeholder="Nee" @click.native="$refs['modal'].hide()" style="background-color: red;" />
              <Button class="button-yes" placeholder="Ja" @click.native="deleteCategory" style="background-color: var(--rik-orange)" />
            </b-modal>
          </b-form>
        </div>
      </div>
    </div>
    <br/>
    <br/>
  </b-container>
</template>

<script>
import BackButton from "../components/BackButton";
import TextField from "../components/TextField";
import TextArea from "../components/TextArea";
import FileInput from "../components/FileInput";
import Button from "../components/Button";
import Select from "../components/Select";
import category from "../store/category";
export default {
  name: "Editcategory",
  components: {Select, Button, FileInput, TextArea, TextField, BackButton},
  data() {
    return {
      type: "",
      parent: "",
      category: {},
      url: "",
      image: "",
      options: [
        {text: "Hoofd thema", value: "main-category"},
        {text: "Sub thema", value: "sub-category"}
      ],
      categories: [],
    }
  },
  methods: {
    onFileChange(e) {
      const file = e.target.files[0];
      this.url = URL.createObjectURL(file);
    },
    editCategory() {
      let category = {
        id: this.$route.params.id,
        name: this.category.name,
        description: this.category.description,
        image: this.category.image,
        parent_id: this.parent
      }
      this.$store.dispatch("editCategory", category);
    },
    async deleteCategory(){
      this.$refs['modal'].hide();
      await this.$store.dispatch("deleteCategory", this.$route.params.id);

      await this.$store.dispatch("getAllCategories", {search: "", tags: "", sort: ""});
      for (const category of this.$store.getters['getCategories'].categories) {
        if (category.parent_id === this.category.id) {
          await this.$store.dispatch("deleteCategory", category.id);
        }
      }
    }
  },
  async beforeCreate(){
    if(localStorage.getItem('access_token')){
      await this.$store.dispatch("getCurrentUser");
      if((this.$store.getters['getCurrentUser'].roles[0].name !== "super-admin")){
        await this.$router.push("/");
      } else {
        await this.$store.dispatch("getAllCategories", {search: "", tags: "", sort: ""});
        this.$store.getters['getCategories'].categories.forEach(category => {
          if (category.parent_id === null) {
            this.categories.push({text: category.name, value: category.id});
          }
        });

        await this.$store.dispatch("getSingleCategory", this.$route.params.id);
        this.category = this.$store.getters['getSingleCategory'];

        if(this.category.parent_id === null){
          this.type = "main-category"
        }
      }
    }
  }
}
</script>

<style scoped>

</style>

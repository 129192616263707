<template>
  <b-container fluid id="additem">
    <b-row class="justify-content-center content">
      <b-col lg="8">
        <BackButton href="/expert" text="Terug naar overzicht" />
        <h1 class="mt-5">Item aanmaken</h1>

        <form @submit.prevent="createItem">
          <div v-if="this.$store.getters['getItemCreatedSuccess']" class="success">Artikel aangemaakt</div>
          <p class="label">Item naam</p>
          <TextField class="mb-4" type="text" placeholder="Item naam" v-model="name" />

          <p class="label">Item type</p>
          <Select class="mb-4" selected-value="Kies een type" placeholder="Kies een type" :options="types"
            v-model="type" />

          <p class="label">Bestuurslaag</p>
          <Select class="mb-4" selected-value="Kies een bestuurslaag" placeholder="Kies een bestuurslaag"
            :options="layersOfGovernment" v-model="layerOfGoverment" />

          <p class="label">Gemeente</p>
          <Select class="mb-4" selected-value="Kies een gemeente" placeholder="Kies een gemeente"
            :options="layersOfTownship" v-model="layerOfTownship" />

          <p class="label">Samenvatting</p>
          <TextArea class="mb-4" placeholder="Begin met typen..." v-model="summary" />

          <p class="label">Thema</p>
          <Select class="mb-4" selected-value="Kies een thema" placeholder="Kies een thema"
            :options="this.$store.getters['getCategoryList']" v-model="theme" />

          <p class="label">Bestanden</p>
          <FileInput v-model="files" placeholder="Kies een bestand..." :multiple="true" types=".pdf, .doc" />

          <p class="label mt-4">Bronnen</p>
          <TextFieldButton v-model="sources" :tags="sources" placeholder="Bronnen" />
          <SourceList :sources="sources" />

          <p class="label mt-4">Aangenomen</p>
          <Checkbox :is-checked="accepted" v-model="accepted" placeholder="Aangenomen" />

          <div v-if="this.$store.getters['getArticleError']" class="error ml-2 mt-4">Niet alle velden zijn correct
            ingevuld</div>
          <Button :loading="isSubmitting" type="submit" id="submit" class="w-75" placeholder="Item aanmaken" />
        </form>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import BackButton from "../components/BackButton";
import TextField from "../components/TextField";
import Select from "../components/Select";
import TextArea from "../components/TextArea";
import FileInput from "../components/FileInput";
import Button from "../components/Button";
import TextFieldButton from "../components/TextFieldButton";
import SourceList from "../components/SourceList";
import Checkbox from "../components/Checkbox";

export default {
  data() {
    return {
      name: "",
      type: "",
      summary: "",
      files: [],
      source: "",
      sources: [],
      theme: "",
      isSubmitting: false,
      types: this.$store.getters['getArticleTypes'],
      layersOfGovernment: [
        { value: 'Noord-Brabant', text: 'Noord-Brabant' },
        { value: 'Limburg', text: 'Limburg' },
        { value: 'Zeeland', text: 'Zeeland' },
        { value: 'Utrecht', text: 'Utrecht' },
        { value: 'Gelderland', text: 'Gelderland' },
        { value: 'Overijssel', text: 'Overijssel' },
        { value: 'Drenthe', text: 'Drenthe' },
        { value: 'Groningen', text: 'Groningen' },
        { value: 'Flevoland', text: 'Flevoland' },
        { value: 'Friesland', text: 'Friesland' },
        { value: 'Noord-Holland', text: 'Noord-Holland' },
        { value: 'Zuid-Holland', text: 'Zuid-Holland' },
        { value: 'Eerste Kamer', text: 'Eerste Kamer' },
        { value: 'Tweede Kamer', text: 'Tweede Kamer' },
      ],
      layerOfGoverment: "",
      layersOfTownship: [
        { value: 'N.V.T.', text: 'N.V.T.' },
        { value: 'Achtkarspelen', text: 'Achtkarspelen' },
        { value: 'Alkmaar', text: 'Alkmaar' },
        { value: 'Amelo', text: 'Amelo' },
        { value: 'Almere', text: 'Almere' },
        { value: 'Alphen aan den Rijn', text: 'Alphen aan den Rijn' },
        { value: 'Amsterdam', text: 'Amsterdam' },
        { value: 'Apeldoorn', text: 'Apeldoorn' },
        { value: 'Arnhem', text: 'Arnhem' },
        { value: 'Beverwijk', text: 'Beverwijk' },
        { value: 'Breda', text: 'Breda' },
        { value: 'Den Haag', text: 'Den Haag' },
        { value: 'Dijk en Waard', text: 'Dijk en Waard' },
        { value: 'Doetinchem', text: 'Doetinchem' },
        { value: 'Dordrecht', text: 'Dordrecht' },
        { value: 'Edam-Volendam', text: 'Edam-Volendam' },
        { value: 'Ede', text: 'Ede' },
        { value: 'Eindhoven', text: 'Eindhoven' },
        { value: 'Emmen', text: 'Emmen' },
        { value: 'Enschede', text: 'Enschede' },
        { value: 'Epe', text: 'Epe' },
        { value: 'Fryske Marren', text: 'Fryske Marren' },
        { value: 'Haarlem', text: 'Haarlem' },
        { value: 'Haarlemmermeer', text: 'Haarlemmermeer' },
        { value: 'Heerlen', text: 'Heerlen' },
        { value: 'Helmond', text: 'Helmond' },
        { value: 'Hengelo', text: 'Hengelo' },
        { value: 'Hoogeveen', text: 'Hoogeveen' },
        { value: 'Hulst', text: 'Hulst' },
        { value: 'Land van Cuijk', text: 'Land van Cuijk' },
        { value: 'Leeuwarden', text: 'Leeuwarden' },
        { value: 'Lelystad', text: 'Lelystad' },
        { value: 'Maashorst', text: 'Maashorst' },
        { value: 'Maastricht', text: 'Maastricht' },
        { value: 'Meierijstad', text: 'Meierijstad' },
        { value: 'Middelburg', text: 'Middelburg' },
        { value: 'Oss', text: 'Oss' },
        { value: 'Purmerend', text: 'Purmerend' },
        { value: 'Roosendaal', text: 'Roosendaal' },
        { value: 'Rotterdam', text: 'Rotterdam' },
        { value: 'Sittard-Geleen', text: 'Sittard-Geleen' },
        { value: 'Sluis', text: 'Sluis' },
        { value: 'Súdwest-Fryslân', text: 'Súdwest-Fryslân' },
        { value: 'Teylingen', text: 'Teylingen' },
        { value: 'Tiel', text: 'Tiel' },
        { value: 'Tilburg', text: 'Tilburg' },
        { value: 'Velsen', text: 'Velsen' },
        { value: 'Venlo', text: 'Venlo' },
        { value: 'Vlissingen', text: 'Vlissingen' },
        { value: 'Westland', text: 'Westland' },
        { value: 'Zoetermeer', text: 'Zoetermeer' },

      ],
      layerOfTownship: "",
      accepted: false
    }
  },
  components: { SourceList, TextFieldButton, Button, FileInput, TextArea, Select, TextField, BackButton, Checkbox },
  methods: {
    addSource() {
      this.sources.push(this.source);
    },
    async createItem() {
      this.isSubmitting = true;
      let article = {
        title: this.name,
        type: this.type,
        description: this.summary,
        category_id: this.theme,
        mediaFiles: this.files,
        sources: this.sources,
        layerOfGoverment: this.layerOfGoverment,
        layerOfTownship: this.layerOfTownship,
        accepted: this.accepted
      }
      await this.$store.dispatch("createArticle", article);
      this.isSubmitting = false;
    }
  },
  async created() {
    await this.$store.dispatch("getListCategories");
  }
}
</script>


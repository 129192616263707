import axios from 'axios';
import router from '../router';

// let url = 'https://ridb-api.retrii.dev'
let url = 'https://api.ridab.nl'
// let url = 'http://127.0.0.1:8000'

const state = {
    reactionCreatedSuccess: false,
    reactionDeletedSuccess: false,
    reaction_error: ""
};

const getters = {
    getReactionCreatedSuccess: (state) => {
        return state.reactionCreatedSuccess;
    },
    getReactionDeletedSuccess: (state) => {
        return state.reactionDeletedSuccess;
    },
    getReactionError: (state) => {
        return state.reaction_error;
    }
};

const actions = {
    async createReaction({ commit }, reaction ) {
        console.log(reaction)
        await axios({
            method: 'post',
            url: `${url}/api/reaction/create`,
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + localStorage.getItem('access_token')
            },
            data: {
                reaction: reaction.reaction,
                question_id: reaction.question_id
            }
        })
            .then((response) => {
                commit("SET_REACTION_CREATED", true);
                commit("SET_REACTION_ERROR", "");
                setTimeout(() => {
                    commit("SET_REACTION_CREATED", false);
                    router.back();
                }, 1500)
            })
            .catch((error) => {
                commit("SET_REACTION_ERROR", error.response.data);
            })
    },
    async deleteReaction(commit, id) {
        await axios.delete(`${url}/api/reaction/${id}`,{
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + localStorage.getItem('access_token')
            },
        })
        .then(response => {
            commit("SET_REACTION_DELETED", true);
            setTimeout(() => {
                commit("SET_REACTION_DELETED", false);
                router.back();
            }, 1500)
        })
        .catch(err =>{
            console.log(err)
        })
    }
};

const mutations = {
    SET_REACTION_CREATED(state, bool) {
        state.reactionCreatedSuccess = bool;
    },
    SET_REACTION_DELETED(state, bool) {
        state.reactionDeletedSuccess = bool;
    },
    SET_REACTION_ERROR(state, error) {
        state.reaction_error = error;
    }
};

export default {
    state,
    getters,
    actions,
    mutations
}

<template>
  <b-container fluid id="menu">
    <b-row class="justify-content-md-center">
      <b-col lg="8" md="12">
        <div class="d-flex" style="justify-content: space-between;">
          <h1>Zoeken in uw thema's</h1>
          <Button
            v-if="this.$store.getters['getCurrentUser'].roles[0].name === 'super-admin' || this.$store.getters['getCurrentUser'].roles[0].name === 'expert' || this.$store.getters['getCurrentUser'].roles[0].name === 'gemeenteraadslid-plus' || this.$store.getters['getCurrentUser'].roles[0].name === 'statenleden-plus'"
            style="margin-top: 60px;" class="h-25" @click.native="$router.push({ name: 'Add item' })"
            placeholder="Artikel toevoegen" />
        </div>
        <SearchBar @search="search" />
        <b-spinner v-if="this.isLoading" style="width: 3rem; height: 3rem;" label="Large Spinner"></b-spinner>
        <h2 class="mt-5" v-if="categories.length === 0 && !this.isLoading"><b>Geen toegewezen thema's</b></h2>
        <b-row class="categories">
          <b-col xl="3" lg="6" md="6" v-for="category in categories" v-if="category.parent_id === null" class="category"
            @click="singleCategory(category.id)">
            <div style="position: relative">
              <div class="item-menu" v-b-tooltip.hover :title="category.name">
                <div class="overlay" />
                <img :src="category.image" alt="">
                <!--                <p v-if="hovering !== category.id" @mouseover="hovering = category.id" @mouseout="hovering = 0" class="title">{{ category.name.split('/')[0] }}</p>-->
                <p :class="'title'">{{ category.name.split('/')[0] }}</p>

              </div>
              <p class="category-label" v-for="sub in categories" v-if="sub.parent_id === category.id">{{ sub.name }}</p>
            </div>

          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import TextField from "../components/TextField";
import Button from "../components/Button";
import SearchBar from "../components/SearchBar";
import router from "../router";
export default {
  components: { SearchBar, Button, TextField },
  data() {
    return {
      searchText: "",
      searchLabel: "",
      categories: [],
      hovering: false,
      isLoading: false
    }
  },
  methods: {
    singleCategory(id) {
      router.push("/category/" + id);
    },
    async search(args) {
      let tags = [];
      args.tags.forEach(tag => {
        tags.push(tag.text);
      });
      tags = tags.join(",");
      await this.$router.push({ name: 'Categories', query: { search: args.value, tags: tags, sort: "desc" } })
    }
  },
  async created() {
    this.isLoading = true;
    await this.$store.dispatch("getCurrentUser");
    await this.$store.dispatch("getAllCategories", { search: "", tags: "", sort: "" });
    this.categories = this.$store.getters['getCategories'].categories;
    this.isLoading = false;
  }
}
</script>

<style scoped>
.container-fluid {
  background-color: var(--default-background) !important;
  min-height: 0;
}

.spinner-border {
  display: block;
  margin: 0 auto;
}
</style>

<template>
  <div id="theme" class="container-fluid">
    <b-row class="justify-content-md-center">
      <b-col lg="10" md="12">

        <h1 class="theme-title">Thema: {{ category.name }}</h1>

        <SearchBar @search="searchCategory" />

        <b-row class="header" align-h="between">
          <b-col class="m-0 p-0" lg="3"><p class="font-weight-bold">Aantal resultaten: {{ articles.length }}</p></b-col>
          <b-col class="m-0 p-0" cols="auto"><SortButton default="desc" @change="this.sortResults"/></b-col>
        </b-row>

        <b-row>
          <b-col lg="5">
            <div class="filters">
              <div style="display: flex" v-b-toggle.collapse-subtheme v-if="sub_categories.length !== 0">
                <p><strong>Filter op subthema</strong></p>
                <Chevrondown/>
              </div>
                <b-form-group v-if="sub_categories.length !== 0"  >
                  <b-collapse id="collapse-subtheme">
                    <b-form-checkbox-group
                        size="lg"
                        id="checkbox-sub"
                        v-model="sub_filters"
                        :options="sub_categories"
                        stacked
                        @change="getResults"
                    ></b-form-checkbox-group>
                  </b-collapse>
                </b-form-group>

              <div style="display: flex" v-b-toggle.collapse-type>
                <p><strong>Filter op</strong></p>
                <Chevrondown/>
              </div>

              <b-form-group>
                <b-collapse id="collapse-type">
                  <b-form-checkbox-group
                      size="lg"
                      id="checkbox-types"
                      v-model="type_filters"
                      :options="types"
                      stacked
                      @change="getResults"
                  ></b-form-checkbox-group>
                </b-collapse>
              </b-form-group>

              <div style="display: flex" v-b-toggle.collapse-layer  v-if="layersOfGovernment.length !== 0">
                <p><strong>Filter op bestuurslaag</strong></p>
                <Chevrondown/>
              </div>

              <b-form-group  v-if="layersOfGovernment.length !== 0">
                <b-collapse id="collapse-layer">
                  <b-form-checkbox-group
                      size="lg"
                      id="checkbox-layer"
                      v-model="layer_of_government"
                      :options="layersOfGovernment"
                      stacked
                      @change="getResults"
                  ></b-form-checkbox-group>
                </b-collapse>
              </b-form-group>

              <div style="display: flex" v-b-toggle.collapse-layer1  v-if="layersOfTownship.length !== 0">
                <p><strong>Filter op gemeente</strong></p>
                <Chevrondown/>
              </div>

              <b-form-group  v-if="layersOfTownship.length !== 0">
                <b-collapse id="collapse-layer1">
                  <b-form-checkbox-group
                      size="lg"
                      id="checkbox-layer1"
                      v-model="layer_of_township"
                      :options="layersOfTownship"
                      stacked
                      @change="getResults"
                  ></b-form-checkbox-group>
                </b-collapse>
              </b-form-group>
            </div>
          </b-col>
          <b-col lg="7">
            <div class="articles">
              <Article v-for="article in articles" :key="article.id" :article="article" />
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import SearchBar from "../components/SearchBar";
import Article from "../components/Article";
import Chevrondown from "../components/icons/Chevrondown";
import Checkbox from "../components/Checkbox";
import Filter from "../components/Filter";
import SortButton from "../components/SortButton";
export default {
  name: "Theme",
  components: {SortButton, Filter, Checkbox, Chevrondown, Article, SearchBar},
  data() {
    return {
      articles: [],
      category: {},
      type_filters: [],
      sub_filters: [],
      layer_of_government: [],
      layer_of_township: [],
      types: [
      ],
      layersOfGovernment: [
      ],
      layersOfTownship: [
      ],
      sub_categories: [],
      sort: "desc"
    }
  },
  methods: {
    filterTypes(articles) {
      let results = []

      articles.forEach(article => {
        if (this.type_filters.find(type => type === article.type)) {
          results.push(article)
        }
      });
      return results
    },
    filterCats(articles) {
      let results = []
      articles.forEach(article => {
        if (this.sub_filters.find(filter => filter === article.cat.id)) {
          results.push(article)
        }
      });
      return results
    },
    filterLayer(articles) {
      let results = []
      articles.forEach(article => {
        this.layer_of_government.forEach(layer => {
          if (article.layer_of_goverment === layer) {
            results.push(article)
          }
        });
      });
      return results
    },
    filterTown(articles) {
      let results = []
      articles.forEach(article => {
        this.layer_of_township.forEach(layer => {
          if (article.layer_of_township === layer) {
            results.push(article)
          }
        });
      });
      return results
    },
    getResults() {

      this.articles = this.category.articles;

      if(this.type_filters.length > 0){
        this.articles = this.filterTypes(this.articles);
      }
      if(this.sub_filters.length > 0){
        this.articles = this.filterCats(this.articles);
      }
      if(this.layer_of_government.length > 0){
        this.articles = this.filterLayer(this.articles);
      }
      if(this.layer_of_township.length > 0){
        this.articles = this.filterTown(this.articles);
      }

      this.articles.forEach(article => {
        if (article.creator === null) {
          article.creator = {name: "verwijderd"};
        }
      });

    },
    async searchCategory(args) {
      let tags = [];
      args.tags.forEach(tag => {
        tags.push(tag.text)
      });
      tags = tags.join(",");
      await this.$store.dispatch("getCategory", {id: this.$route.params.id, search: args.value, tags: tags, sort: this.sort });
      this.category = this.$store.getters['getCategory'];
      this.getResults()
    },
    sortResults (sort) {
      this.sort = sort;
      this.searchCategory({value: "", tags: []})
    }
  },
  computed:{

  },
  async created() {
    await this.$store.dispatch("getCategory", {id: this.$route.params.id, search: "", tags: "", sort: this.sort });
    this.category = this.$store.getters['getCategory'];
    this.getResults();

    let types = [];
    let locations = [];
    let townships = [];


    this.category.categories.forEach(sub => {
      this.articles.forEach(article => {
        if(sub.name === article.cat.name){
          if(!this.sub_categories.some(cat => cat.text === article.cat.name)){
            this.sub_categories.push({text: sub.name, value: sub.id});
          }
        }
        types.push(article.type);
        locations.push(article.layer_of_goverment);
        townships.push(article.layer_of_township);
      })
    })

    const populateLayerList = (text, list) =>{
      if (text !== '' || text.length > 0){
        list.push({text: text.charAt(0).toUpperCase() + text.slice(1), value: text.replace(' ', '-')})
      }
    }
    types.map((v) => {
      if (!this.types.find(i => i.value === v)) {
        populateLayerList(v, this.types);
      }
    });
    locations.map((v) => {
      if (!this.layersOfGovernment.find(i => i.value === v)) {
        populateLayerList(v, this.layersOfGovernment);
      }
    });

    townships.map((v) => {
      if (!this.layersOfTownship.find(i => i.value === v)) {
        populateLayerList(v, this.layersOfTownship);
      }
    });

    this.sub_categories.shift();
  }
}
</script>

<style scoped>
.container-fluid {
  background-color: var(--default-background) !important;
  min-height: 0;
}
</style>

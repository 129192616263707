<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.2827 5L20.9998 12L14.2827 19" stroke="var(--default-text)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <line x1="19.7329" y1="12.0317" x2="3.99985" y2="12.0317" stroke="var(--default-text)" stroke-width="2" stroke-linecap="round"/>
  </svg>
</template>

<script>
export default {
  name: "ArrowRight"
}
</script>

<style scoped>

</style>
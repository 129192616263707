import Vue from 'vue'
import Vuex from 'vuex'
import user from './user'
import admin from './admin'
import question from './question'
import category from './category'
import article from './article'
import reaction from './reaction'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user,
    admin,
    question,
    category,
    article,
    reaction
  }
})

<template>
  <b-container id="additem" fluid>
    <div v-if="$store.getters['getItemDeletedSuccess']" style="padding-top: 150px;">
      <b-spinner
        style="width: 3rem; height: 3rem; padding: 80px auto; margin: 0 auto; text-align: center; display: block; "
        label="Large Spinner"></b-spinner>
    </div>

    <b-row v-else class="justify-content-center content">
      <b-col lg="7">
        <BackButton href="/expert" text="Terug naar overzicht" />
        <h1 class="mt-5">Item bewerken</h1>

        <form @submit.prevent="updateArticle">
          <div v-if="this.$store.getters['getArticleError']" class="error ml-2">Niet alle velden zijn correct ingevuld
          </div>
          <div v-if="this.$store.getters['getItemUpdatedSuccess']" class="success">Artikel bewerkt</div>
          <p class="label">Item naam</p>
          <TextField class="mb-4" type="text" placeholder="Item naam" :value="article.title" v-model="article.title" :disabled="!canEdit"/>

          <p class="label">Item type</p>
          <Select class="mb-4" placeholder="Kies een type" :selected-value="article.type" :options="types"
            v-model="article.type" :disabled="!canEdit"/>

          <p class="label">Bestuurslaag</p>
          <Select class="mb-4" placeholder="Kies een bestuurslaag" :selected-value="article.layer_of_goverment"
            :options="layersOfGovernment" v-model="article.layer_of_goverment" :disabled="!canEdit"/>

          <p class="label">Gemeente</p>
          <Select class="mb-4" placeholder="Kies een bestuurslaag" :selected-value="article.layer_of_township"
            :options="layersOfTownship" v-model="article.layer_of_township" :disabled="!canEdit"/>

          <p class="label">Samenvatting</p>
          <TextArea class="mb-4" placeholder="Begin met typen..." :value="article.description"
            v-model="article.description" :disabled="!canEdit"/>

          <p class="label">Thema</p>
          <Select class="mb-4" placeholder="Kies een thema" :selected-value="article.category_id.toString()"
            :options="categories" v-model="article.category_id" :disabled="!canEdit"/>

          <p class="label">Bestanden</p>
          <FileInput :value="article.files" v-model="article.files" placeholder="Kies een bestand..." types=".pdf, .doc"
            :multiple="true" :disabled="!canEdit"/>

          <p class="label mt-4">Bronnen</p>
          <!--        <TextFieldButton v-model="this.sources" placeholder="Bronnen" />-->
          <div class="input tf-btn-wrapper">
            <b-form-input v-model="source" type="text" class="txt-field shadow-none" placeholder="Bronnen" :disabled="canEdit"></b-form-input>
            <b-button @click="addSource" class="Button tf-btn shadow-none" :disabled="!canEdit">Add</b-button>
          </div>
          <SourceList :sources="this.sources" />

          <p class="label mt-4">Aangenomen</p>
          <Checkbox :is-checked="article.accepted" v-model="article.accepted" placeholder="Aangenomen" />

          <div v-if="canEdit">
            <Button type="submit" id="submit" class="w-50" placeholder="Item bewerken" />
            <Button style="margin-top:20px;" @click.native="deleteArticle" class="w-50" placeholder="Item verwijderen" />
          </div>
        </form>
      </b-col>
    </b-row>

  </b-container>
</template>
<script>
import BackButton from "../components/BackButton";
import TextField from "../components/TextField";
import Select from "../components/Select";
import TextArea from "../components/TextArea";
import FileInput from "../components/FileInput";
import Button from "../components/Button";
import TextFieldButton from "../components/TextFieldButton";
import SourceList from "../components/SourceList";
import article from "../store/article";
import Checkbox from "../components/Checkbox";

export default {
  data() {
    return {
      article: {},
      categories: [],
      sources: [],
      source: "",
      canEdit: false,
      types: this.$store.getters['getArticleTypes'],
      layersOfGovernment: [
        { value: 'Noord-Brabant', text: 'Noord-Brabant' },
        { value: 'Limburg', text: 'Limburg' },
        { value: 'Zeeland', text: 'Zeeland' },
        { value: 'Utrecht', text: 'Utrecht' },
        { value: 'Gelderland', text: 'Gelderland' },
        { value: 'Overijssel', text: 'Overijssel' },
        { value: 'Drenthe', text: 'Drenthe' },
        { value: 'Groningen', text: 'Groningen' },
        { value: 'Flevoland', text: 'Flevoland' },
        { value: 'Friesland', text: 'Friesland' },
        { value: 'Noord-Holland', text: 'Noord-Holland' },
        { value: 'Zuid-Holland', text: 'Zuid-Holland' },
        { value: 'Eerste Kamer', text: 'Eerste Kamer' },
        { value: 'Tweede Kamer', text: 'Tweede Kamer' },
      ],
      layerOfGoverment: "",
      layersOfTownship: [
        { value: 'Achtkarspelen', text: 'Achtkarspelen' },
        { value: 'Alkmaar', text: 'Alkmaar' },
        { value: 'Amelo', text: 'Amelo' },
        { value: 'Almere', text: 'Almere' },
        { value: 'Alphen aan den Rijn', text: 'Alphen aan den Rijn' },
        { value: 'Amsterdam', text: 'Amsterdam' },
        { value: 'Apeldoorn', text: 'Apeldoorn' },
        { value: 'Arnhem', text: 'Arnhem' },
        { value: 'Beverwijk', text: 'Beverwijk' },
        { value: 'Breda', text: 'Breda' },
        { value: 'Den Haag', text: 'Den Haag' },
        { value: 'Dijk en Waard', text: 'Dijk en Waard' },
        { value: 'Doetinchem', text: 'Doetinchem' },
        { value: 'Dordrecht', text: 'Dordrecht' },
        { value: 'Edam-Volendam', text: 'Edam-Volendam' },
        { value: 'Ede', text: 'Ede' },
        { value: 'Eindhoven', text: 'Eindhoven' },
        { value: 'Emmen', text: 'Emmen' },
        { value: 'Enschede', text: 'Enschede' },
        { value: 'Epe', text: 'Epe' },
        { value: 'Fryske Marren', text: 'Fryske Marren' },
        { value: 'Haarlem', text: 'Haarlem' },
        { value: 'Haarlemmermeer', text: 'Haarlemmermeer' },
        { value: 'Heerlen', text: 'Heerlen' },
        { value: 'Helmond', text: 'Helmond' },
        { value: 'Hengelo', text: 'Hengelo' },
        { value: 'Hoogeveen', text: 'Hoogeveen' },
        { value: 'Hulst', text: 'Hulst' },
        { value: 'Land van Cuijk', text: 'Land van Cuijk' },
        { value: 'Leeuwarden', text: 'Leeuwarden' },
        { value: 'Lelystad', text: 'Lelystad' },
        { value: 'Maashorst', text: 'Maashorst' },
        { value: 'Maastricht', text: 'Maastricht' },
        { value: 'Meierijstad', text: 'Meierijstad' },
        { value: 'Middelburg', text: 'Middelburg' },
        { value: 'Oss', text: 'Oss' },
        { value: 'Purmerend', text: 'Purmerend' },
        { value: 'Roosendaal', text: 'Roosendaal' },
        { value: 'Rotterdam', text: 'Rotterdam' },
        { value: 'Sittard-Geleen', text: 'Sittard-Geleen' },
        { value: 'Sluis', text: 'Sluis' },
        { value: 'Súdwest-Fryslân', text: 'Súdwest-Fryslân' },
        { value: 'Tiel', text: 'Tiel' },
        { value: 'Tilburg', text: 'Tilburg' },
        { value: 'Velsen', text: 'Velsen' },
        { value: 'Venlo', text: 'Venlo' },
        { value: 'Vlissingen', text: 'Vlissingen' },
        { value: 'Westland', text: 'Westland' },
        { value: 'Zoetermeer', text: 'Zoetermeer' },

      ],
      layerOfTownship: ""
    }
  },
  components: { SourceList, TextFieldButton, Button, FileInput, TextArea, Select, TextField, BackButton, Checkbox },
  methods: {
    addSource() {
      this.sources.push(this.source);
    },
    updateArticle() {
      this.article.sources = this.sources;
      this.$store.dispatch("updateArticle", this.article);
    },
    deleteArticle() {
      this.$store.dispatch("deleteArticle", this.article.id);
    }
  },
  async created() {

    if (localStorage.getItem('access_token')) {
      await this.$store.dispatch("getCurrentUser");
      await this.$store.dispatch("getListCategories");
      await this.$store.dispatch("getArticle", this.$route.params.id);
      this.article = this.$store.getters['getArticle'];

      if(await this.$store.getters['getCurrentUser'].id === this.article.user_id || await this.$store.getters['getCurrentUser'].roles[0].name === "super-admin") {
        this.canEdit = true;
      }

      this.categories = this.$store.getters['getCategoryList'];

      if(this.article.sources !== null && this.article.sources !== "")
        this.sources = this.article.sources.split(',');
    }
    


    if (this.$store.getters['getCurrentUser'].roles[0].name !== "super-admin" && this.$store.getters['getArticle'].user_id !== this.$store.getters['getCurrentUser'].id) {
      alert("U heeft geen toegang tot deze pagina")
      await this.$router.push("/");
    }
  }

}
</script>


<template>
  <b-container fluid id="notifications">
    <b-row class="justify-content-center">
      <b-col lg="10">
        <BackButton text="Terug naar overzicht" href="/admin" />

        <div class="tile mt-5">
          <p><strong>Meldingen</strong></p>
<!--          <TextField @keyup.native="searchQuestions" @change.native="searchQuestions" v-model="searchedQuestion" class="expert-input" icon="search" placeholder="Zoeken"/>-->
          <div class="table-responsive">
            <table class="table " style="color: var(--default-text) !important;">
              <thead>
              <tr>
                <th class="no-top-border" scope="col">Type</th>
                <th class="no-top-border" scope="col">Inhoud</th>
                <th class="no-top-border" scope="col">Datum</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="notification in this.notifications" :key="notification.id">
                <td>{{ notification.type }}</td>
                <td>{{ notification.text }}</td>
                <td>{{ notification.created_at }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import BackButton from "../../components/BackButton";
export default {
  name: "Notifications",
  components: {BackButton},
  data() {
    return {
      notifications: []
    }
  },
  async created() {
    await this.$store.dispatch("getNotifications");
    this.notifications = this.$store.getters['getNotifications'];

    this.notifications.forEach(n => {
      let str = n.text;
      str = str.replaceAll("<", "*");
      str = str.replaceAll(">", "*");
      str = str.replaceAll("*br*", " ");
      str = str.replaceAll("*b*", "");
      str = str.replaceAll("*/b*", "");
      n.text = str;
    });
  }
}
</script>

<style scoped>
</style>
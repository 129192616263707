<template>
  <div class="select-wrapper">
    <select :disabled="disabled" v-model="selected" @change="click" class="select">
      <option disabled selected>{{ placeholder}}</option>
      <option v-for="option in options" :value="option.value">
        {{ option.text }}
      </option>
    </select>
    <Chevrondown/>
  </div>
</template>

<script>
import ArrowLeft from "./icons/ArrowLeft";
import Chevrondown from "./icons/Chevrondown";

export default {
  name: "Select",
  components: {ArrowLeft,Chevrondown},
  data () {
    return {
      selected: ""
    }
  },
  methods: {
    click() {
      this.$emit('input', this.content = this.selected);
    }
  },
  props: ["options", "placeholder", "selectedValue", "disabled"],
   created() {
    setTimeout(() => {
      this.selected = this.selectedValue;

    },500)
  }
}
</script>

<template>
  <div id="nav-bar" v-if="this.$store.getters['getCurrentUser']">
    <b-navbar toggleable="lg">
      <b-navbar-brand><router-link to="/"><img class="logo" src="../assets/images/fvd-logo.png"
            alt=""></router-link></b-navbar-brand>
      <b-nav-text disabled class="admin-name">{{ this.$store.getters['getCurrentUser'].name }},
        {{ this.$store.getters['getCurrentUser'].roles[0].name }}</b-nav-text>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-nav-item v-if="this.rank === 'super-admin'"><router-link class="mr-4 mb-1" to="/admin/notifications">
              <Notification />
            </router-link></b-nav-item>
          <b-nav-item v-if="this.rank === 'super-admin'"><router-link class="nav-item" to="/admin"
              style="color: var(--default-text) !important;">Admin paneel</router-link></b-nav-item>
          <b-nav-item v-if="this.rank === 'expert'"><router-link class="nav-item" to="/expert"
              style="color: var(--default-text) !important;">Expert paneel</router-link></b-nav-item>

          <b-nav-item v-if="this.rank === 'statenleden-plus' || this.rank === 'gemeenteraadslid-plus'"><router-link
              class="nav-item" to="/mijn-artikelen" style="color: var(--default-text) !important;">Mijn
              items</router-link></b-nav-item>

          <b-nav-item v-if="this.rank === 'statenlid-plus'"><router-link class="nav-item" to="/expert"
              style="color: var(--default-text) !important;">Mijn items</router-link></b-nav-item>
          <b-nav-item v-if="this.rank === 'user'"><router-link class="nav-item" to="/questions"
              style="color: var(--default-text) !important;">Overzicht vragen</router-link></b-nav-item>
          <b-nav-item v-if="this.rank === 'user'"><router-link class="nav-item" to="/asked-questions"
              style="color: var(--default-text) !important;">Jouw gestelde vragen</router-link></b-nav-item>
          <b-nav-item v-if="this.rank === 'user'"><router-link class="nav-item" to="/ask-question"
              style="color: var(--default-text) !important;">Stel een vraag</router-link></b-nav-item>
          <b-nav-item v-if="this.rank === 'user' || this.rank === 'expert'"><router-link class="nav-item"
              to="/mijn-account" style="color: var(--default-text) !important;">Mijn account</router-link></b-nav-item>

          <b-nav-item><a @click="logout" class="log-out" style="color: var(--default-text) !important;">
              <LogOut class="mb-1 mr-2" /> Uitloggen
            </a></b-nav-item>
          <!--          <b-nav-item><a  @click="toggleTheme" aria-label="Toggle themes">-->
          <!--            <span v-if="this.theme == 'darkMode'" style="color: var(&#45;&#45;default-text) !important;"> Light</span>-->
          <!--            <span v-else style="color: var(&#45;&#45;default-text) !important;"> Dark</span>-->
          <!--          </a></b-nav-item>-->
          <b-nav-item>
            <div class="mode-toggle" @click="toggleTheme">
              <div class="toggle">
                <div id="dark-mode" type="checkbox"></div>
              </div>
            </div>
          </b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <!--    <router-link to="/"><img class="logo" src="../assets/images/fvd-logo.png" alt=""></router-link>-->
    <!--    <p class="admin-name">{{this.$store.getters['getCurrentUser'].name}}, {{this.$store.getters['getCurrentUser'].roles[0].name}} </p>-->

    <!--&lt;!&ndash;    <Notifications v-if="this.$store.getters['getCurrentUser'].roles[0].name === 'super-admin'" :notifications="notifications" />&ndash;&gt;-->
    <!--    <router-link class="mr-4 mb-1" v-if="this.$store.getters['getCurrentUser'].roles[0].name === 'super-admin'" to="/admin/notifications"><Notification /></router-link>-->

    <!--    <router-link v-if="this.$store.getters['getCurrentUser'].roles[0].name === 'super-admin'" class="nav-item" to="/admin">Admin paneel</router-link>-->
    <!--    <router-link v-if="this.$store.getters['getCurrentUser'].roles[0].name === 'expert'" class="nav-item" to="/expert">Expert paneel</router-link>-->
    <!--    <router-link v-if="this.$store.getters['getCurrentUser'].roles[0].name === 'user'" class="nav-item" to="/questions">Overzicht vragen</router-link>-->
    <!--    <router-link v-if="this.$store.getters['getCurrentUser'].roles[0].name === 'user'" class="nav-item" to="/asked-questions">Jouw gestelde vragen</router-link>-->
    <!--    <router-link v-if="this.$store.getters['getCurrentUser'].roles[0].name === 'user'" class="nav-item" to="/ask-question">Stel een vraag</router-link>-->

    <!--    <div><a @click="logout" class="log-out"><LogOut class="mb-1 mr-2"/> Uitloggen</a></div>-->
  </div>
</template>

<script>
import LogOut from "./icons/LogOut";
import BackButton from "./BackButton";
import Notifications from "./Notifications";
import Notification from "./icons/Notification";
export default {
  components: { Notification, Notifications, BackButton, LogOut },
  data() {
    return {
      rank: "",
      theme: ''
    }
  },
  methods: {
    logout() {
      this.$store.dispatch("userLogout");
    },
    dark() {
      document.querySelector('body').classList.add('dark-mode');
      this.$emit('dark');
    },

    light() {
      document.querySelector('body').classList.remove('dark-mode');
      this.$emit('light');
    },


    toggleTheme() {
      this.theme = this.theme === 'darkMode' ? '' : 'darkMode'; //toggles theme value
      document.documentElement.setAttribute('data-theme', this.theme); // updates the data-theme attribute
      localStorage.setItem('theme', this.theme); // stores theme value in local storage
      if (this.theme) {
        this.dark();
      } else {
        this.light();
      }
    }
  },
  async created() {
    await this.$store.dispatch('getCurrentUser');
    this.rank = this.$store.getters['getCurrentUser'].roles[0].name;
    let localTheme = localStorage.getItem('theme'); //gets stored theme value if any
    document.documentElement.setAttribute('data-theme', localTheme); // updates the data-theme attribute
    if (localTheme === 'darkMode') {
      this.dark()
    } else {
      this.light()
    }
  },
  mounted() {
    let localTheme = localStorage.getItem('theme'); //gets stored theme value if any
    document.documentElement.setAttribute('data-theme', localTheme); // updates the data-theme attribute
  },


}
</script>

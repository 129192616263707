<template>
  <div id="login" class="container-fluid m-0 p-0 w-100">
    <b-row  class="m-0 p-0">
      <b-col class="image-logo m-0 p-0">
        <img class="bg" src="../assets/images/school_of_athens.jpg" alt="">
        <div class="content">
          <img class="logo" src="../assets/images/fvd-logo.png" alt="">
          <h1 class="header-text">Renaissance Instituut Kennisbank</h1>
        </div>
      </b-col>
      <b-col class="m-0 p-0" align-self="center">
        <b-form class="login-form" @submit.prevent="newPassword">
          <h1>Herstel wachtwoord</h1>
          <div v-if="this.$store.getters['getUserError'] || error" class="error">Niet alle velden zijn correct ingevuld</div>
          <TextField class="mt-3" type="password" placeholder="Nieuw wachtwoord" v-model="password"/>
          <TextField class="mt-3" type="password" placeholder="Herhaal wachtwoord" v-model="password2"/>
          <Button class="mt-3" type="submit" placeholder="Herstel wachtwoord"/>
        </b-form>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import TextField from "../components/TextField";
import Button from "../components/Button";
export default {
  name: "ResetPassword",
  components: {Button, TextField},
  props: ["token", "email"],
  data() {
    return {
      password: "",
      password2: "",
      error: false
    }
  },
  methods: {
    newPassword() {
      if (this.password2 !== "" && this.password === this.password2) {
        this.$store.dispatch("userReset", {token: this.$route.query.token, email: this.$route.query.email, password: this.password});
        return;
      }
      this.error = true;
    }
  }
}
</script>
import axios from 'axios';
import router from '../router';

// let url = 'https://ridb-api.retrii.dev'
let url = 'https://api.ridab.nl'
// let url = 'http://127.0.0.1:8000'

const state = {
    categories: [],
    categoryList: [],
    categoryCreatedSuccess: false,
    categoryDeletedSuccess: false,
    category: "",
    singleCategory: "",
    category_error: ""
};

const getters = {
    getCategories: (state) => {
        return state.categories;
    },
    getCategoryList: (state) => {
        return state.categoryList;
    },
    getCategory: (state) => {
        return state.category;
    },
    getCategoryCreatedSuccess: (state) => {
        return state.categoryCreatedSuccess
    },
    getCategoryDeletedSuccess: (state) => {
        return state.categoryDeletedSuccess;
    },
    getSingleCategory: (state) => {
        return state.singleCategory;
    },
    getCategoryError: (state) => {
        return state.category_error;
    }
};

const actions = {
    async getAllCategories({commit}, {search, tags, sort}) {
        if (tags !== "") {
            console.log(tags)
            tags = tags.toLowerCase();
        }
        if (search !== "") {
            search = search.toLowerCase();
        }
        await axios.get(`${url}/api/categories?search=${search}&tags=${tags}&sort=${sort}`, {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + localStorage.getItem('access_token')
            }
        }).then((response) => {
            commit("SET_CATEGORIES", response.data)
        }).catch((error) => {
            console.log(error.message)
        })
    },
    async getListCategories({commit}) {
        await axios.get(`${url}/api/categorylist`, {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + localStorage.getItem('access_token')
            }
        }).then((response) => {
            commit("SET_CATEGORY_LIST", response.data)
        }).catch((error) => {
            console.log(error.message)
        })
    },
    async getCategory({commit}, {id, search, tags, sort}) {
        if (tags !== "") {
            tags = tags.toLowerCase();
        }
        if (search !== "") {
            search = search.toLowerCase();
        }
        await axios.get(`${url}/api/category/${id}?search=${search}&tags=${tags}&sort=${sort}`, {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + localStorage.getItem('access_token')
            }
        }).then((response) => {
            commit("SET_CATEGORY", response.data)
        }).catch((error) => {
            console.log(error.message)
        })
    },async getSingleCategory({commit}, id) {
        await axios.get(`${url}/api/single/category/${id}`, {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Bearer " + localStorage.getItem('access_token')
            }
        }).then((response) => {
            commit("SET_SINGLE_CATEGORY", response.data)
        }).catch((error) => {
            console.log(error.message)
        })
    },
    async createCategory({commit}, category) {
        let formData = new FormData();
        if (category.name !== "") {
            formData.append("name", category.name);
        }
        if (category.description !== "") {
            formData.append("description", category.description);
        }
        if (category.image !== "") {
            formData.append('image', category.image);
        }
        if(category.parentId !== ""){
            formData.append('parent_id', category.parent_id);
        }
        let config = {
            headers:{
                "Authorization": "Bearer " + localStorage.getItem('access_token'),
                'Content-Type': 'multipart/form-data',
                "Accept": "application/json"
            },
        }
        await axios.post(`${url}/api/category/create`, formData, config)
            .then(() => {
                commit("SET_CATEGORY_CREATED", true);
                commit("SET_CATEGORY_ERROR", "")
                setTimeout(() => {
                    commit("SET_CATEGORY_CREATED", false);
                    router.back();
                }, 1500)
            })
            .catch((error) => {
                commit("SET_CATEGORY_ERROR", error.response.data)
            })
    },
    async editCategory({commit}, category) {
        let formData = new FormData();
        if (category.name !== "") {
            formData.append("name", category.name);
        }
        if (category.description !== "") {
            formData.append("description", category.description);
        }
        if (category.image !== "") {
            formData.append('image', category.image);
        }
        if(category.parent_id !== ""){
            formData.append('parent_id', category.parent_id);
        }
        let config = {
            headers:{
                "Authorization": "Bearer " + localStorage.getItem('access_token'),
                'Content-Type': 'multipart/form-data',
                "Accept": "application/json"
            },
        }

        await axios.post(`${url}/api/category/${category.id}`, formData, config)
            .then(() => {
                commit("SET_CATEGORY_CREATED", true);
                commit("SET_CATEGORY_ERROR", "");
                setTimeout(() => {
                    commit("SET_CATEGORY_CREATED", false);
                    router.back();
                }, 1500)
            })
            .catch((error) => {
                commit("SET_CATEGORY_ERROR", error.response.data);
            })
    },
    async deleteCategory({commit}, id){
        let config = {
            headers:{
                "Authorization": "Bearer " + localStorage.getItem('access_token'),
                "Accept": "application/json"
            },
        }
       await axios.delete(`${url}/api/category/${id}`, config)
           .then(() => {
               commit("SET_CATEGORY_DELETED", true);
               setTimeout(() => {
                   commit("SET_CATEGORY_DELETED", false);
                   router.back();
               }, 1500)
           })
           .catch((error) => {
               console.log(error)
           })
    }

};

const mutations = {
    SET_CATEGORIES(state, categories){
        state.categories = categories;
    },
    SET_CATEGORY_LIST(state, categories){
        state.categoryList = categories;
    },
    SET_CATEGORY(state, category){
        state.category = category;
    },
    SET_CATEGORY_CREATED(state, bool) {
        state.categoryCreatedSuccess = bool;
    },
    SET_CATEGORY_DELETED(state, bool){
        state.categoryDeletedSuccess = bool
    },
    SET_SINGLE_CATEGORY(state, category) {
        state.singleCategory = category;
    },
    SET_CATEGORY_ERROR(state, error) {
        state.category_error = error;
    }
};

export default {
    state,
    getters,
    actions,
    mutations
}

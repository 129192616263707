<template>
  <b-container fluid id="detail">
    <b-row class="content justify-content-md-center">
      <b-col lg="5" sm="8">
        <BackButton :href="`/category/${article.category_id}`" text="Terug naar overzicht" />
        <h1 class="label mt-5">{{ article.title }}</h1>
        <div class="creation">
          <div class="creator">
            <Person />
            {{ article.creator.name }}
          </div>
          <div class="date">
            <Calendar />
            {{ article.created_at }}
          </div>
        </div>

        <div class="mt-5">
          <p> <strong>Locatie: </strong>{{ article.layer_of_goverment }}</p>
        </div>

        <h3 class="label">Samenvatting</h3>
        <p>{{ article.description }}</p>

        <ul class="files">
          <li v-for="file in article.article_files" class="file">
            <a :href="file.source" class="link" target="_blank">
              <File /><span class="link-text">{{ file.source }}</span>
            </a>
          </li>
        </ul>

        <h3 class="label" v-if="article.sources">Bronvermelding</h3>
        <ul class="sources">
          <li v-for="source in article.sources" class="source">
            <a :href="source" class="link" target="_blank">{{ source }}</a>
          </li>
        </ul>

        <ShareNetwork class="mt-3" style="display: block; color: var(--default-text) !important;" network="whatsapp"
          :url="'https://ridab.nl/public/' + article.public_id" :title="article.title">
          Deel op whatsapp
        </ShareNetwork>

        <p v-for="tag in article.Tags" :key="article.id" class="article_tag">{{ tag.name.nl }}</p>
        <h3 class="label">Reacties</h3>
        <div class="comment" v-for="comment in this.reOrderComments()">
          <span @click="deleteComment(comment.id)" v-if="$store.getters['getCurrentUser'].roles[0].name === 'super-admin'"
            style="float:right;">X</span>
          <p> {{ comment.user.name }} - {{ comment.user.political_role }}</p>
          <p> {{ comment.comment_text }}</p>
          <span @click="commentReactionId = comment.id">Reageren</span>
          <b-form class="reply" v-if="commentReactionId === comment.id" @submit.prevent="sendComment">
            <TextField class="mb-4" type="text" placeholder="Uw reactie" :value="commentToPost" v-model="commentToPost" />
            <Button type="submit" class="mt-3" placeholder="Reactie plaatsen" />
          </b-form>
          <div class="comment mt-1" v-for="reply in comment.replies">
            <span @click="deleteComment(reply.id)" v-if="$store.getters['getCurrentUser'].roles[0].name === 'super-admin'"
              style="float:right;">X</span>
            <p> {{ reply.user.name }} - {{ reply.user.political_role }}</p>
            <p> {{ reply.comment_text }}</p>
            <span @click="commentReactionId = reply.id">Reageren</span>
            <b-form class="reply" v-if="commentReactionId === reply.id" @submit.prevent="sendComment">
              <TextField class="mb-4" type="text" placeholder="Uw reactie" :value="commentToPost"
                v-model="commentToPost" />
              <Button type="submit" class="mt-3" placeholder="Reactie plaatsen" />
            </b-form>
          </div>
        </div>
        <b-form @submit.prevent="sendComment">
          <TextField @click.native="commentReactionId = null" class="mb-4" type="text" placeholder="Uw reactie"
            :value="commentToPost" v-model="commentToPost" />
          <Button type="submit" class="mt-3 mb-5" placeholder="Reactie plaatsen" />
        </b-form>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import BackButton from "../components/BackButton";
import Person from "../components/icons/Person";
import Calendar from "../components/icons/Calendar";
import File from "../components/icons/File";
import TextField from "../components/TextField";
import Button from "../components/Button";

import router from "../router";
export default {
  components: { File, Calendar, Person, BackButton, TextField, Button },
  data() {
    return {
      article: {},
      commentToPost: "",
      commentReactionId: null
    }
  },
  async created() {
    await this.$store.dispatch("getArticle", this.$route.params.id);
    this.article = this.$store.getters['getArticle'];

    if (this.article.creator === null) {
      this.article.creator = { name: "verwijderd" };
    }
    if (this.article.sources !== null) {
      this.article.sources = this.article.sources.split(",");
    }
  },
  methods: {
    async deleteComment(id) {
      await this.$store.dispatch('deleteComment', id);
      await this.$store.dispatch("getArticle", this.$route.params.id);
      this.article = this.$store.getters['getArticle'];
    },
    async sendComment() {
      let commentObject = {
        id: this.$route.params.id,
        comment_text: this.commentToPost,
        comment_id: this.commentReactionId
      }

      await this.$store.dispatch('comment', commentObject);
      await this.$store.dispatch("getArticle", this.$route.params.id);
      this.article = this.$store.getters['getArticle'];
      this.comment = "";
    },
    reOrderComments() {
      const comments = this.article.comment;

      const commentsById = {};
      const result = [];

      comments.forEach(comment => {
        if (!comment.comment_id) {
          result.push(comment);
        } else {
          if (!commentsById[comment.comment_id]) {
            commentsById[comment.comment_id] = [];
          }
          commentsById[comment.comment_id].push(comment);
        }
      });

      result.forEach(comment => {
        comment.replies = commentsById[comment.id] || [];
      });

      console.log(result);

      return result;
    }
  }
}
</script>

<style scoped>
.comment {
  border: 3px solid var(--rik-gray);
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.content {
  width: 100% !important;
}

#detail {
  background-color: var(--default-background) !important;
}

button {
  margin-top: 0 !important;
}

.reply {
  padding: 15px;
}

span {
  cursor: pointer;
}
</style>

